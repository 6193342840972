import React from 'react'
import axios from 'axios'
import moment from 'moment'
import styles from './Vulnerability.module.css'
import DataList from '../UI/DataList'
import { Button } from 'antd'
import { vulnerabilityStrings } from '../../services/vulnerability'
import { countVulnerabilities, listVulnerabilities } from '../../services/api'

const ByMonthList = ({ customerFiId, selectVulnerability = () => {} }) => {
  const defaultSortOrder = { field: 'date', order: 'descend' }

  const dataFilter = {
    target: 'monthly_breakdown',
    'customer.fi_id': customerFiId
  }

  const dataUpdater = (filterString, dataSetter, countSetter) => {
    return axios.all([listVulnerabilities(filterString), countVulnerabilities(filterString)])
      .then(axios.spread(({ data: vulnerabilities }, { data: count }) => {
        countSetter(count)

        dataSetter(
          vulnerabilities.map(item => ({
            key: item.guid,
            date: item.date,
            status: item.event_status
          }))
        )
      }))
  }

  const renderMonth = (month, data) => {
    return <Button
      value={moment(month).format('MMMM')}
      type='link'
      onClick={() => {
        selectVulnerability(data)
      }}
      className={[styles.month_btn, 'default_color']}
    >
      {moment(month).format('MMMM')}
    </Button>
  }

  const renderStatus = (status, data) => {
    return <span className={ data.status }>
      {vulnerabilityStrings[data.status]}
    </span>
  }

  const renderYear = (year) => {
    return <span>{moment(year).format('YYYY')}</span>
  }

  // Column preparation
  const columns = [
    {
      title: 'Year',
      dataIndex: 'date',
      align: 'center',
      width: 150,
      render: renderYear
    },
    {
      title: 'Month',
      dataIndex: 'date',
      align: 'left',
      render: renderMonth
    },
    {
      title: 'Status',
      dataIndex: 'event_status',
      width: 250,
      render: renderStatus
    }
  ]

  return (
    <>
       <div className={styles.horizontal_divider} />
      <DataList
        onRow={(row) => ({
          onClick: () => {
            selectVulnerability(row)
          }
        })}
        {...{ columns, defaultSortOrder, dataUpdater, dataFilter }}
      />
    </>
  )
}

export default ByMonthList
