import React from 'react'

const Logo = ({ fill, width = 45, height = 26 }) => {
  return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 25 26"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M0 3.6C0 3.6 0.947639 10.176 5.81433 18.168C8.67331 22.856 11.7893 26 11.7893 26C11.7893 26 16.8166 20.36 19.8121 14.096C22.8076 7.832 24.5262 2.04 25 0C25 0 14.6242 7.672 0 3.6ZM6.86637 17.36C3.14809 10.808 1.88725 5.792 1.88725 5.792C1.88725 5.792 3.46129 6.264 4.87472 6.416C4.87472 6.416 6.28815 14.088 11.1629 23.648C11.1629 23.648 9.14713 21.384 6.86637 17.36Z"
                fill={fill}
            />
        </svg>
  )
}
export default Logo
