import React from 'react'

const createColumn = ({ title, dataIndex, width, fixed, align = 'left', render = (value) => <span className='default_color'>{value}</span> }) => {
  const column = {
    title,
    dataIndex,
    align,
    render
  }
  if (width) {
    column.width = width
  }
  if (fixed) {
    column.fixed = fixed
  }
  return column
}
const createSortableColumn = ({
  title, dataIndex, width, fixed, sortOrder, sortDirections,
  align = 'left',
  render = (value) => <span className='default_color'>{value}</span>
}) => {
  const column = {
    title,
    dataIndex,
    sortOrder: sortOrder.field === dataIndex && sortOrder.order,
    sortDirections: sortDirections || SORT_DIRECTIONS,
    sorter: () => 0, // always returns true to not modify response. Response will be ordered in server
    align,
    render
  }
  if (width) {
    column.width = width
  }
  if (fixed) {
    column.fixed = fixed
  }
  return column
}

const SORT_DIRECTIONS_MAP = {
  ascend: 'asc',
  descend: 'desc'
}

const SORT_DIRECTIONS = Object.keys(SORT_DIRECTIONS_MAP)

const DEFAULT_PAGE_SIZE = window.REACT_APP_DEFAULT_PAGE_SIZE
const DEFAULT_PAGE_SIZE_OPTIONS = [5, DEFAULT_PAGE_SIZE, 20, 50].sort((a, b) => a - b)

const DEFAULT_PAGINATION = {
  defaultPageSize: DEFAULT_PAGE_SIZE,
  pageSizeOptions: DEFAULT_PAGE_SIZE_OPTIONS,
  showLessItems: true,
  size: 'default'
}

export { SORT_DIRECTIONS_MAP, DEFAULT_PAGE_SIZE_OPTIONS, DEFAULT_PAGE_SIZE, DEFAULT_PAGINATION }
export { createColumn, createSortableColumn }
