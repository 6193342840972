import CallApi from '../apiClient'

const VUMS_API_HOST = window.REACT_APP_KALGERA_VUMS_API_HOST

const getFullUrl = path => VUMS_API_HOST + path

const routes = {
  vulnerability_list: { method: 'GET', url: getFullUrl('/vulnerabilities') },
  vulnerability_stats_list: { method: 'GET', url: getFullUrl('/vulnerabilities/stats') },
  vulnerability_count: { method: 'GET', url: getFullUrl('/vulnerabilities/count') },
  customer_list: { method: 'GET', url: getFullUrl('/customers') },
  customer_count: { method: 'GET', url: getFullUrl('/customers/count') },
  customer_statistic: { method: 'GET', url: getFullUrl('/customers-count') },
  vulnerability_event_list: { method: 'GET', url: getFullUrl('/vulnerability-events') },
  vulnerability_event_count: { method: 'GET', url: getFullUrl('/vulnerability-events/count') },
  transaction_list: { method: 'GET', url: getFullUrl('/transactions') },
  transaction_count: { method: 'GET', url: getFullUrl('/transactions/count') }
}

const listVulnerabilities = async (params) => {
  return CallApi({ ...routes.vulnerability_list, params })
}

const listVulnerabilitiesStats = async (params) => {
  return CallApi({ ...routes.vulnerability_stats_list, params })
}

const countVulnerabilities = async (params) => {
  return CallApi({ ...routes.vulnerability_count, params })
}

const listCustomers = async (params) => {
  return CallApi({ ...routes.customer_list, params })
}

const countCustomers = async (params) => {
  return CallApi({ ...routes.customer_count, params })
}

const listVulnerabilityEvents = async (params) => {
  return CallApi({ ...routes.vulnerability_event_list, params })
}

const countVulnerabilityEvents = async (params) => {
  return CallApi({ ...routes.vulnerability_event_count, params })
}

const listTransactions = async (params) => {
  return CallApi({ ...routes.transaction_list, params })
}

const countTransactions = async (params) => {
  return CallApi({ ...routes.transaction_count, params })
}

const customerStatistic = async (params) => {
  return CallApi({ ...routes.customer_statistic, params })
}

export {
  listVulnerabilities,
  listVulnerabilitiesStats,
  countVulnerabilities,
  listCustomers,
  countCustomers,
  listVulnerabilityEvents,
  countVulnerabilityEvents,
  listTransactions,
  countTransactions,
  customerStatistic
}
