import React from 'react'
import { connect } from 'react-redux'
import { InputNumber } from 'antd'
import styles from './Above.module.css'
import * as actionCreators from '../../../../../store/actions'

const filterKey = 'above'

const Above = ({ numberOfTransaction, storeFilter }) => {
  const inputNumberChangeHandler = numberOfTransaction => {
    storeFilter(filterKey, numberOfTransaction, null)
  }

  return (
        <InputNumber
            min={1}
            value={numberOfTransaction}
            onChange={inputNumberChangeHandler}
            className={styles.InputNumber}
        />
  )
}

const mapStateToProps = state => {
  const from = state.financialAbuseRiskFilter.numberOfTransactions.value.from
  const key = state.financialAbuseRiskFilter.numberOfTransactions.key

  return {
    numberOfTransaction: key === filterKey ? from : null
  }
}

const mapDispatchToProps = dispatch => {
  return {
    storeFilter: (key, from, to) => dispatch(actionCreators.filterNumberOfTransactionsRange(key, from, to))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Above)
