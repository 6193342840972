export const buildCustomerFilters = ({ timeline, numberOfAccounts, numberOfTransactions, filterTerm = '', vulnerabilityStatuses = [] }) => {
  const customerFilters = []
  const vulnerabilityFilters = []

  // if (timeline.value.from) {
  //   filters.push({ 'vulnerabilities.date_gte': timeline.value.from.valueOf() })
  // }

  if (timeline.value.to) {
    customerFilters.push({ 'vulnerabilities.date_lte': timeline.value.to.valueOf() })
  }

  if (numberOfAccounts.value.from) {
    customerFilters.push({ total_accounts_gte: numberOfAccounts.value.from })
  }

  if (numberOfAccounts.value.to) {
    customerFilters.push({ total_accounts_lte: numberOfAccounts.value.to })
  }

  if (numberOfTransactions.value.from) {
    customerFilters.push({ total_transactions_gte: numberOfTransactions.value.from })
  }

  if (numberOfTransactions.value.to) {
    customerFilters.push({ total_transactions_lte: numberOfTransactions.value.to })
  }

  if (filterTerm.length > 0) {
    customerFilters.push({ fi_id_contains: filterTerm })
  }

  if (vulnerabilityStatuses.length > 0) {
    vulnerabilityFilters.push({ event_status_in: vulnerabilityStatuses })
  }

  return { _where: customerFilters, vulnerabilityFilters }
}

export const buildVulnerabilityFilters = ({ timeline, numberOfAccounts, numberOfTransactions, vulnerabilityStatuses = [] }) => {
  const filters = []

  if (timeline.value.from) {
    filters.push({ date_gte: timeline.value.from.valueOf() })
  }

  if (timeline.value.to) {
    filters.push({ date_lte: timeline.value.to.valueOf() })
  }

  if (numberOfAccounts.value.from) {
    filters.push({ 'customer.total_accounts_gte': numberOfAccounts.value.from })
  }

  if (numberOfAccounts.value.to) {
    filters.push({ 'customer.total_accounts_lte': numberOfAccounts.value.to })
  }

  if (numberOfTransactions.value.from) {
    filters.push({ 'customer.total_transactions_gte': numberOfTransactions.value.from })
  }

  if (numberOfTransactions.value.to) {
    filters.push({ 'customer.total_transactions_lte': numberOfTransactions.value.to })
  }

  if (vulnerabilityStatuses.length > 0) {
    filters.push({ event_status_in: vulnerabilityStatuses })
  }

  return { _where: filters }
}

export const buildCustomerStatisticFilters = ({
  timeline,
  numberOfAccounts,
  numberOfTransactions,
  vulnerabilityStatuses = []
}) => {
  const filters = []
  //
  // if (timeline.value.from) {
  //   filters.push({ 'vulnerabilities.date_gte': timeline.value.from.valueOf() })
  // }

  if (timeline.value.to) {
    filters.push({ 'vulnerabilities.date_lte': timeline.value.to.valueOf() })
  }

  if (numberOfAccounts.value.from) {
    filters.push({ total_accounts_gte: numberOfAccounts.value.from })
  }

  if (numberOfAccounts.value.to) {
    filters.push({ total_accounts_lte: numberOfAccounts.value.to })
  }

  if (numberOfTransactions.value.from) {
    filters.push({ total_transactions_gte: numberOfTransactions.value.from })
  }

  if (numberOfTransactions.value.to) {
    filters.push({ total_transactions_lte: numberOfTransactions.value.to })
  }

  // if (vulnerabilityStatuses.length > 0) {
  //   filters.push({ last_event_status_in: vulnerabilityStatuses })
  // }

  return { _where: filters }
}
