import React, { useState } from 'react'
import axios from 'axios'
import moment from 'moment'
import DataList from '../UI/DataList'
import styles from './DataUploadHistory.module.css'
import { CardContainer, IconTrash, Avatar } from '@tutorbot/ui-components'
import { listFiles, countFiles, listUsers, deleteFile } from '../../services/api'
import { useDispatch, useSelector } from 'react-redux'
import { setListLastUpdatedAt } from '../../store/actions'
import { deleteFileConfirmation } from '../UI/Modal/ModalContent'
import { Button } from 'antd'

const getStatusLabel = status => {
  const statusMap = {
    // In progress statuses
    partitioning_customers: 'Analysing...',
    processing: 'Analysing...',
    analysing: 'Analysing...',
    processed: 'Success',
    uploading: 'Uploading...',

    // waiting statuses
    partitioned: 'Active',
    queued: 'Active',

    // Error statuses
    status_conflict_1: 'Unsuccessful / Unexpected Status',
    corrupted_file: 'Unsuccessful / Invalid File Format',
    customer_id_not_found: 'Unsuccessful / Customer Id missing'
  }
  return statusMap[status]
}

const DataUploadHistory = () => {
  const defaultSortOrder = { field: 'created_at', order: 'descend' }
  const [creators, setCreators] = useState({})
  const dispatch = useDispatch()
  const lastUpdatedAt = useSelector((state) => state.lastUpdatedAtEvent.listLastUpdatedAt)
  const userGuid = useSelector((state) => state.auth.user.guid)

  const dataUpdater = (filterString, dataSetter, countSetter, postUpdateChecker) => {
    return axios.all([listFiles(filterString), countFiles(filterString)])
      .then(axios.spread(async ({ data: files }, { data: count }) => {
        if (postUpdateChecker(files, count) === false) {
          return
        }

        countSetter(count)

        const usersGuids = new Set()
        files.forEach(({ created_by: createdBy }, i) => {
          files[i].key = i
          if (!creators[createdBy]) {
            usersGuids.add(createdBy)
          }
        })

        if (usersGuids.size) {
          const { data: users } = await listUsers({ guid_in: [...usersGuids] })
          users.forEach(({ guid, first_name: firstName, last_name: lastName, profileImage }) => {
            let profileImageUrl = null
            if (profileImage) {
              profileImageUrl = profileImage.url.indexOf('http') === 0
                ? profileImage.url
                : `/api/ums${profileImage.url}`
            }
            creators[guid] = { firstName, lastName, profileImageUrl }
          })
          setCreators(creators)
        }

        dataSetter(files)
      }))
  }

  const renderDate = (value) => (
    <span>{moment(value).format('DD-MM-YY')}</span>
  )

  const renderStatus = (value) => {
    let style = 'warning'

    switch (value) {
      case 'partitioning_customers':
      case 'partitioned':
      case 'processed':
        style = 'success'
        break

      case 'uploading':
      case 'queued':
      case 'processing':
        style = 'warning'
        break

      case 'analysing':
      case 'status_conflict_1':
      case 'corrupted_file':
      case 'customer_id_not_found':
        style = 'danger'
        break

      default:
    }

    return (<span className={styles[style]}>{getStatusLabel(value)}</span>)
  }

  const handleDeleteFileConfirmation = (record) => {
    deleteFileConfirmation(record, () => {
      deleteFile(record.guid).finally(() => dispatch(setListLastUpdatedAt(moment().valueOf())))
    })
  }

  const renderUploader = (value, record) => {
    const user = creators[value] || {}
    return (
      <div className={styles.uploader_container}>
        <Avatar size={'x'} src={user.profileImageUrl || '/img_avatar.png'}/>
        <span className='default_color'>{user.firstName} {user.lastName}</span>
        <Button
          disabled={userGuid !== value}
          type='link'
          htmlType='button'
          onClick={() => handleDeleteFileConfirmation(record)}
        >
          <IconTrash fill={userGuid === value ? 'var(--black-60)' : 'var(--black-20)'} />
        </Button>
      </div>
    )
  }

  const sortDirections = ['descend', 'ascend']
  const columns = [
    { title: 'Date', dataIndex: 'created_at', isSortable: true, render: renderDate, sortDirections },
    { title: 'File type', dataIndex: 'type', isSortable: true },
    { title: 'File name', dataIndex: 'name', isSortable: true },
    { title: 'Status', dataIndex: 'status', isSortable: true, render: renderStatus },
    { title: 'Uploader', dataIndex: 'created_by', render: renderUploader }
  ]

  return (
    <div className={styles.ContainerWrapper}>
      <CardContainer>
        <div className={styles.list_top}>
          <h1 className={styles.title}>Data upload history</h1>
        </div>
        <DataList {...{ columns, defaultSortOrder, dataUpdater, lastUpdatedAt: lastUpdatedAt }}/>
      </CardContainer>
    </div>
  )
}

export default DataUploadHistory
