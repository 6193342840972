import React, { useRef } from 'react'
import './FileUpload.css'
import { Form, Modal } from 'antd'
import {
  uploadConfirmation,
  uploadUnsuccessfulConfirmation,
  uploadSuccessfulConfirmation,
  Alert
} from '../UI/Modal/ModalContent'
import { v4 as uuidv4 } from 'uuid'
import { Button, IconUploadOutlined, IconCloseOutlined } from '@tutorbot/ui-components'
import { createFileChunk, createFile, countFiles } from '../../services/api'
import { useDispatch } from 'react-redux'
import { setListLastUpdatedAt } from '../../store/actions'
import moment from 'moment'

const FILE_CHUNK_SIZE = window.REACT_APP_FILE_UPLOAD_CHUNK_SIZE

const FileUpload = () => {
  const fileInput = useRef()
  const dispatch = useDispatch()

  let _selectedFile = null
  let _fileName = ''
  let _fileGuid = ''
  let _counter = 0
  let _beginningOfTheChunk = 0
  let _endOfTheChunk = FILE_CHUNK_SIZE
  let _chunkCount = 0

  const resetProperties = () => {
    resetFileProperties()
    resetChunkProperties()
  }

  const resetFileProperties = () => {
    _selectedFile = null
    _fileName = ''
    _fileGuid = ''
  }

  const resetChunkProperties = () => {
    _counter = 0
    _beginningOfTheChunk = 0
    _endOfTheChunk = FILE_CHUNK_SIZE
    _chunkCount = 0
  }

  const validateUpload = (file) => {
    const isCsv = file && (file.type === 'text/csv' || file.type === 'application/vnd.ms-excel' || file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')

    if (!isCsv) {
      const icon = <IconCloseOutlined size={'sm'} fill={'var(--error-color)'} />
      const title = 'Error'
      const content = <p>You can only upload CSV file!</p>

      Alert(title, content, icon, () => { Modal.destroyAll() })
      return false
    }
    return isCsv
  }

  const fileUpload = () => {
    if (_counter++ <= _chunkCount) {
      const chunk = _selectedFile.slice(_beginningOfTheChunk, _endOfTheChunk)
      uploadChunk(chunk)
    }
  }

  const uploadChunk = async (chunk) => {
    try {
      const { data } = await createFileChunk({
        chunkName: _fileGuid,
        chunkNumber: _counter,
        chunkContent: chunk,
        fileName: _fileName
      })

      if (data && data.isSuccess) {
        _beginningOfTheChunk = _endOfTheChunk
        _endOfTheChunk = _endOfTheChunk + FILE_CHUNK_SIZE
        if (_counter === _chunkCount) {
          await uploadCompleted()
          dispatch(setListLastUpdatedAt(moment().valueOf()))
        } else {
          fileUpload()
        }
      } else {
        uploadUnsuccessfulConfirmation(_fileName, handleOpenFileInput, handleReset)
        console.log('Error Occurred:', data.error)
      }
    } catch (error) {
      uploadUnsuccessfulConfirmation(_fileName, handleOpenFileInput, handleReset)
      console.log('error', error)
    }
  }

  const uploadCompleted = async () => {
    try {
      await createFile({
        chunkName: _fileGuid,
        fileName: _fileName
      })
      uploadSuccessfulConfirmation(_fileName)
    } catch (err) {
      console.log('Error: ', err)
    }
  }

  const handleSelectedFile = async (event) => {
    const file = event.currentTarget.files[0]
    const fileName = event.target.files ? event.target.files[0].name : ''

    const filename = fileName.replace(/ /g, '_').substring(0, fileName.lastIndexOf('.'))
    const { data: count } = await countFiles(`name=${filename}`)

    if (!file && !fileName) {
      return
    }
    if (validateUpload(file)) {
      resetProperties()

      // Total count of chunks will have been upload to finish the file
      const totalCount = file.size % FILE_CHUNK_SIZE === 0
        ? file.size / FILE_CHUNK_SIZE
        : Math.floor(file.size / FILE_CHUNK_SIZE) + 1

      _selectedFile = file
      _fileName = fileName
      _chunkCount = totalCount
      _fileGuid = uuidv4()

      if (count > 0) {
        const icon = <IconCloseOutlined size={'sm'} fill={'var(--error-color)'} />
        const title = 'Error'
        const content = <p>File already exist.</p>

        Alert(title, content, icon, handleReset)
        return false
      } else {
        uploadConfirmation(file, handleSubmit, handleReset)
      }
    }
    // reset input field
    handleReset()
  }

  const handleReset = () => {
    fileInput.current && (fileInput.current.value = '')
  }

  const handleSubmit = async () => {
    fileUpload(_counter)
  }

  const handleOpenFileInput = () => {
    fileInput.current && fileInput.current.click()
  }

  return (
        <Form>
            <input
                accept='.csv, text/csv'
                style={{ display: 'none' }}
                ref={fileInput}
                type='file'
                name='file'
                onChange={handleSelectedFile}
            />
            <Button size='middle' onClick={handleOpenFileInput} className='button' >
                Upload data
                <IconUploadOutlined size={'sm'} />
            </Button>
        </Form>
  )
}

export default FileUpload
