import React from 'react'
import { Modal } from 'antd'
import { IconCloseOutlined, IconHelpCircle } from '@tutorbot/ui-components'
import './ModalContent.css'

const uploadConfirmation = (file, handleSubmit, handleReset) => {
  return (
    Modal.confirm({
      title: 'Upload confirmation',
      icon: <IconHelpCircle fill='var(--warning-color)' size={'sm'} />,
      content: (<p>Please confirm this file upload. <span className='text_color'>{file.name}</span></p>),
      className: 'modal_dialog',
      centered: true,
      okText: 'Upload',
      cancelText: 'Cancel',
      onOk: handleSubmit,
      onCancel: handleReset
    })
  )
}

const uploadSuccessfulConfirmation = (fileName, handleSubmit = () => {}) => {
  return (
    Modal.confirm({
      title: 'Upload Successful',
      icon: <IconHelpCircle fill='var(--warning-color)' size={'sm'} />,
      content: (<p>The file <span className='text_color'>{fileName}</span> successfully uploaded.</p>),
      className: 'modal_dialog',
      centered: true,
      okText: 'Ok',
      onOk: handleSubmit
    })
  )
}

const uploadDataPrompt = (handleOpenFileInput, handleReset) => {
  return (
    Modal.confirm({
      title: 'Upload data',
      icon: <IconHelpCircle fill='var(--warning-color)' size={'sm'} />,
      content: <p>To start making use of the analytics tools you will need to upload your first sample of data.</p>,
      className: 'modal_dialog',
      centered: true,
      okText: 'Upload data',
      cancelText: 'Upload later',
      onOk: handleOpenFileInput,
      onCancel: handleReset
    })
  )
}

const logOutDialog = (handleOk, handleCancel) => {
  return (
    Modal.confirm({
      title: 'Log out',
      icon: <IconHelpCircle fill='var(--warning-color)' size={'sm'} />,
      content: <p>Are you sure you wish to log out?</p>,
      className: 'modal_dialog',
      centered: true,
      okText: 'Log out',
      cancelText: 'Cancel',
      onOk: handleOk,
      onCancel: handleCancel
    })
  )
}

const uploadUnsuccessfulConfirmation = (fileName, handleOpenFileInput, handleReset) => {
  return (
    Modal.confirm({
      title: 'Upload unsuccessful',
      icon: <IconHelpCircle fill='var(--warning-color)' size={'sm'} />,
      content: (
        <div className='modal_content'>
          <p>The file below failed to upload successfully. <span className='text_color'>{fileName}</span></p>
          <p>Failure could be due to one of the following:</p>
          <ul className='reason_list'>
            <li>Incorrect file type</li>
            <li>Incorrect data format</li>
            <li>Corrupt file</li>
          </ul>
          <p>Check the file upload guide for help.
            <span className='text_color'>
              <a className='guide_label' href='/'>Data upload guide</a>
            </span>
          </p>
        </div>
      ),
      className: 'modal_dialog',
      centered: true,
      okText: 'Re-upload',
      cancelText: 'Cancel',
      onOk: handleOpenFileInput,
      onCancel: handleReset
    })
  )
}

const globalErrorAlert = (errors) => {
  return (
    Modal.info({
      title: 'Unexpected error',
      icon: <IconCloseOutlined size={'sm'} fill={'var(--error-color)'} />,
      content: (
        <div className='modal_content'>
          <p>Failure could be due to one of the following:</p>
          <ul className='reason_list'>
            {errors.map((item, i) =>
              <li key={i}>{item}</li>
            )}
          </ul>
        </div>
      ),
      className: 'modal_dialog global_error_dialog',
      centered: true,
      closable: false
    })
  )
}

const Alert = (title, content, icon, onOk = () => {}, closable = false) => {
  return (
    Modal.info({
      title: title,
      icon: icon,
      content: content,
      className: 'modal_dialog alert',
      centered: true,
      okText: 'Ok',
      closable,
      onOk: onOk
    })
  )
}

const deleteFileConfirmation = (file, handleDelete, handleCancel) => {
  return (
    Modal.confirm({
      title: 'Delete file',
      icon: <IconCloseOutlined fill='var(--error-color)' size={'sm'} />,
      content: (<p>Are you sure you wish to permanently delete this file?
          <span className='text_color'>{file.name}</span></p>
      ),
      className: 'modal_dialog delete_file',
      centered: true,
      okType: 'danger',
      okText: 'Delete',
      cancelText: 'Cancel',
      onOk: handleDelete,
      onCancel: handleCancel
    })
  )
}

export {
  uploadConfirmation,
  uploadDataPrompt,
  uploadUnsuccessfulConfirmation,
  uploadSuccessfulConfirmation,
  globalErrorAlert,
  Alert,
  logOutDialog,
  deleteFileConfirmation
}
