import * as actionTypes from './actionTypes'

// Timeline filter actions
export const filterTimelineReset = () => {
  return {
    type: actionTypes.FARF_TIMELINE_RESET,
    key: null,
    value: { from: null, to: null }
  }
}

export const filterTimelineRange = (key, from, to) => {
  return {
    type: actionTypes.FARF_TIMELINE_RANGE,
    key: key,
    value: { from, to }
  }
}

// Number of transactions filter actions
export const filterNumberOfTransactionsReset = () => {
  return {
    type: actionTypes.FARF_NUMBER_OF_TRANSACTIONS_RESET,
    key: null,
    value: { from: null, to: null }
  }
}

export const filterNumberOfTransactionsRange = (key, from, to) => {
  return {
    type: actionTypes.FARF_NUMBER_OF_TRANSACTIONS_RANGE,
    key: key,
    value: { from, to }
  }
}

// Number of accounts filter actions
export const filterNumberOfAccountsReset = () => {
  return {
    type: actionTypes.FARF_NUMBER_OF_ACCOUNTS_RESET,
    key: null,
    value: { from: null, to: null }
  }
}

export const filterNumberOfAccountsRange = (key, from, to) => {
  return {
    type: actionTypes.FARF_NUMBER_OF_ACCOUNTS_RANGE,
    key: key,
    value: { from, to }
  }
}

export const setSelectedVulnerabilityStatuses = (value) => {
  return {
    type: actionTypes.FARF_SELECTED_VULNERABILITY_STATUSES,
    value: value
  }
}
