import * as actionTypes from '../actions/actionTypes'
import cloneDeep from 'lodash/cloneDeep'

const initialState = {
  vulnerability: {}
}

const handleSelectedVulnerability = (state = initialState, action) => {
  const clonedState = cloneDeep(state)

  switch (action.type) {
    case actionTypes.HANDLE_SELECTED_VULNERABILITY:
      clonedState.vulnerability = action.value
      return clonedState
    default:
      return state
  }
}

export default handleSelectedVulnerability
