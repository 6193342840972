import React from 'react'
import { Button, Text } from '@tutorbot/ui-components'
import { Link } from 'react-router-dom'
import styles from '../../components/Gateway/Registration.module.css'
import { resendVerification } from '../../services/api'
import { useSelector } from 'react-redux'

const VerifyEmail = ({ verifyEmail }) => {
  const { email } = useSelector(state => state.auth.user)
  const resendVerificationHandler = () => {
    resendVerification({ email })
      .then(({ data }) => {
        console.log('Your user received an email', data.sent)
      })
  }

  let activeBlock

  switch (verifyEmail) {
    case 1:
      activeBlock = (
                <>
                    <>
                        <p className={styles.text}>
                            We have sent you a link to verify your email address.
                        </p>
                        <p className={styles.text}>
                            If you don’t see the email within 10 minutes, please
                            click the button below to re-send it.
                        </p>
                    </>
                    <Button
                        className={styles.button}
                        onClick={resendVerificationHandler}
                    >
                        Re-send email
                    </Button>
                </>
      )
      break
    case 2:
      activeBlock = (
                <>
                    <p className={styles.text}>
                        Thank you for verifying your email address!
                    </p>
                    <Link to="/">
                        <Button className={styles.button}>
                            Continue to dashboard
                        </Button>
                    </Link>
                </>
      )
      break
    default:
  }
  return (
        <div className={styles.verifyEmailWrapper}>
            <Text level={1} semiBold>
                Verify email
            </Text>
            {activeBlock}
        </div>
  )
}
export default VerifyEmail
