import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Input } from 'antd'
import { IconSearchOutlined } from '@tutorbot/ui-components'
import { useDispatch, useSelector } from 'react-redux'
import { handleInput, handleStatisticView } from '../../../store/actions'
import styles from './SearchInput.module.css'

const SearchInput = () => {
  const minQueryLength = 4

  const filterTerm = useSelector(state => state.searchTerm.term)
  const [query, setQuery] = useState(filterTerm)

  const dispatch = useDispatch()
  const history = useHistory()

  const doSearch = (query) => {
    history.push('/')
    dispatch(handleInput(query))
    dispatch(handleStatisticView('order-list'))
  }
  useEffect(() => {
    if (query.length >= minQueryLength) {
      doSearch(query)
    } else if (query.length === 0) {
      dispatch(handleInput(''))
    }
  }, [dispatch, query])

  const handleChange = (event) => {
    const {
      target: { value }
    } = event
    setQuery(value)
  }
  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      doSearch(query)
    }
  }

  return (
        <div className={styles.search_container}>
            <Input
                className={styles.search_input}
                name='search'
                prefix={<IconSearchOutlined fill={'var(--black-40)'} size={'sm'} />}
                placeholder='Search'
                type='search'
                allowClear
                autoComplete='off'
                value={query}
                onChange={handleChange}
                onKeyPress={handleKeyPress}
            />
        </div>
  )
}

export default SearchInput
