import React, { useState } from 'react'
import { Form, Card } from 'antd'
import { Button, Text } from '@tutorbot/ui-components'
import Input from '../../components/UI/Input/Input'
import styles from '../../components/Gateway/Registration.module.css'
import { password, confirmPassword } from '../../components/Gateway/FormInputs'
import HeaderLogo from '../../components/Gateway/HeaderLogo'
import { resetPassword, handleAuthenticationData } from '../../services/api'
import { useQuery } from '../../services/urlQuery'

const resetPasswordFormItems = [password, confirmPassword]

const ResetPassword = () => {
  const query = useQuery()

  const [successfullyReset, setSuccessfullyReset] = useState(false)
  const [form] = Form.useForm()

  const resetPasswordHandle = (values) => {
    resetPassword({
      password: values[password.fieldName],
      // @todo rename the field confirmPassword to passwordConfirmation
      passwordConfirmation: values[confirmPassword.fieldName],
      code: query.get('code')
    })
      .then(({ data }) => {
        handleAuthenticationData(data)
        setSuccessfullyReset(true)

        // @todo maybe redirect to homepage
      }).catch(error => {
        const errors = error.response.data.message

        if (errors.length && errors[0].messages[0].id === 'Auth.form.error.code.provide') {
          // @todo set form error
          alert('Password Reset link is invalid.')
        }
        console.error(error)
      })
  }

  return <Card className={styles.container} bodyStyle={{ padding: 0 }}>
            <HeaderLogo />
            <div className={styles.verifyEmailWrapper}>
                <Text level={1} semiBold>Reset password</Text>

                {!successfullyReset
                  ? <p className={styles.text}>Please create a new password to continue.</p>
                  : <p className={styles.text}>Your new password has been successfully saved.</p>
                }

                {!successfullyReset
                  ? <Form
                        form={form}
                        layout="vertical"
                        initialValues={{}}
                        className="form"
                        validateTrigger="onBlur"
                        onFinish={resetPasswordHandle}
                    >
                        {
                            resetPasswordFormItems.map((item, index) => (
                                <Form.Item
                                    key={index}
                                    name={item.fieldName}
                                    label={item.name}
                                    rules={item.rules}
                                >
                                    <Input
                                        placeholder={item.placeholder}
                                        type={item.type}
                                        prefix={item.icon}
                                    />
                                </Form.Item>
                            ))
                        }
                        <Form.Item>
                            <Button className={styles.button}>Save new password</Button>
                        </Form.Item>
                    </Form>
                  : ''}

            </div>
        </Card>
}
export default ResetPassword
