import React, { useEffect, useState, useCallback } from 'react'
import Popover from '../../UI/Popover/Popover'
import * as content from '../../UI/Popover/Popover.content'
import { CircularProgressbar } from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'
import { Text, CardContainer, IconInfoCircle } from '@tutorbot/ui-components'
import { customerStatistic } from '../../../services/api'
import { useSelector } from 'react-redux'
import { buildCustomerStatisticFilters } from '../../../services/filterBuilder'
import axios from 'axios'
import moment from 'moment-timezone'
import styles from './StatisticList.module.css'
import percentRound from 'percent-round'

const StatisticList = () => {
  const [customerStats, setCustomerStats] = useState({})
  const formatDate = 'DD MMM Y'

  const [vulnerablePercent, potentiallyVulnerablePercent, noVulnerablePercent] = customerStats.total
    ? percentRound([
      customerStats.vulnerability / customerStats.total,
      customerStats.potentially_vulnerability / customerStats.total,
      customerStats.not_vulnerable / customerStats.total
    ])
    : [0, 0, 0]

  // filter +
  const timeline = useSelector(state => state.financialAbuseRiskFilter.timeline)
  const numberOfTransactions = useSelector(state => state.financialAbuseRiskFilter.numberOfTransactions)
  const numberOfAccounts = useSelector(state => state.financialAbuseRiskFilter.numberOfAccounts)
  const isStatusAbsolute = useSelector(state => state.statusGraphic.isStatusAbsolute)

  // high level vulnerability status selector
  const selectedStatuses = useSelector(state => state.financialAbuseRiskFilter.selectedVulnerabilityStatuses)

  const getCustomerStatisticFilters = useCallback(() => {
    return buildCustomerStatisticFilters({
      timeline,
      numberOfTransactions,
      numberOfAccounts,
      vulnerabilityStatuses: selectedStatuses
    })
  }, [
    timeline.value.to,
    timeline.value.from,
    numberOfTransactions.value.to,
    numberOfTransactions.value.from,
    numberOfAccounts.value.to,
    numberOfAccounts.value.from,
    selectedStatuses.join(' '),
    isStatusAbsolute
  ])

  const refreshStatistics = useCallback(() => {
    const filters = getCustomerStatisticFilters()
    axios.all([
      customerStatistic(filters)
    ])
      .then(
        axios.spread(({ data: stats }) => {
          setCustomerStats({
            vulnerability: stats.vulnerable,
            potentially_vulnerability: stats.potentially_vulnerable,
            not_vulnerable: stats.not_vulnerable,
            total: stats.total,
            from: stats.from,
            to: stats.to
          })
        }))
      .catch(err => console.log(err))
  }, [getCustomerStatisticFilters])

  useEffect(() => {
    refreshStatistics()
  }, [getCustomerStatisticFilters])

  return (
        <div className={styles.statistic_list}>
            <CardContainer>
                <div className={styles.statistic_header}>
                    <div className={styles.header_first}>
                        <Text>Statistic</Text>
                        <Text>
                          {moment(customerStats.to).tz('UTC').format(formatDate)}
                        </Text>
                    </div>
                    <div className={styles.header_second}>
                        <Text>Total customers: {customerStats.total}</Text>
                    </div>
                </div>
                <div className={styles.horizontal_divider} />
                <div className={styles.statistic_base_container}>
                    <div className={styles.statistic_container}>
                        <div className={styles.circle_diagram}>
                            <CircularProgressbar
                                value={vulnerablePercent / 100}
                                maxValue={1}
                                text={`${Math.round(vulnerablePercent)}%`}
                                styles={{
                                  root: {
                                    width: '87px',
                                    height: '87px'
                                  },
                                  path: { stroke: 'var(--error-color)' },
                                  trail: { stroke: 'var(--background-color)' },
                                  text: {
                                    fontStyle: 'normal',
                                    fontWeight: 600,
                                    fontSize: '20px',
                                    lineHeight: '26px',
                                    fill: 'var(--primary-color)'
                                  }
                                }}
                            />
                        </div>
                        <div className={styles.circle_diagram_text}>
                            <p>
                                Vulnerable
                                <Popover placement="topRight" content={content.vulnerable} trigger="hover">
                                   <IconInfoCircle fill={'var(--primary-color)'} />
                                </Popover>
                            </p>
                            <p className={styles.customer_count}>{customerStats.vulnerability} customers</p>
                        </div>
                    </div>
                    <div className={styles.vertical_divider} />
                    <div className={styles.statistic_container}>
                        <div className={styles.circle_diagram}>
                            <CircularProgressbar
                                value={potentiallyVulnerablePercent / 100}
                                maxValue={1}
                                text={`${Math.round(potentiallyVulnerablePercent)}%`}
                                styles={{
                                  root: {
                                    width: '87px',
                                    height: '87px'
                                  },
                                  path: { stroke: 'var(--warning-color)' },
                                  trail: { stroke: 'var(--background-color)' },
                                  text: {
                                    fontStyle: 'normal',
                                    fontWeight: 600,
                                    fontSize: '20px',
                                    lineHeight: '26px',
                                    fill: 'var(--primary-color)'
                                  }
                                }}
                            />
                        </div>
                        <div className={styles.circle_diagram_text}>
                            <p>
                                Potentially vulnerable
                                <Popover placement="topRight" content={content.potentiallyVulnerable} trigger="hover">
                                    <IconInfoCircle fill={'var(--primary-color)'} />
                                </Popover>
                            </p>
                            <p className={styles.customer_count}>{customerStats.potentially_vulnerability} customers</p>
                        </div>
                    </div>
                    <div className={styles.vertical_divider} />
                    <div className={styles.statistic_container}>
                        <div className={styles.circle_diagram}>
                            <CircularProgressbar
                                value={noVulnerablePercent / 100}
                                maxValue={1}
                                text={`${Math.round(noVulnerablePercent)}%`}
                                styles={{
                                  root: {
                                    width: '87px',
                                    height: '87px'
                                  },
                                  path: { stroke: 'var(--success-color)' },
                                  trail: { stroke: 'var(--background-color)' },
                                  text: {
                                    fontStyle: 'normal',
                                    fontWeight: 600,
                                    fontSize: '20px',
                                    lineHeight: '26px',
                                    fill: 'var(--primary-color)'
                                  }
                                }}
                            />
                        </div>
                        <div className={styles.circle_diagram_text}>
                            <p>
                                Not vulnerable
                                <Popover placement="topLeft" content={content.notVulnerable} >
                                    <IconInfoCircle fill={'var(--primary-color)'} />
                                </Popover>
                            </p>
                            <p className={styles.customer_count}>{customerStats.not_vulnerable} customers</p>
                        </div>
                    </div>
                </div>
            </CardContainer>
        </div>
  )
}

export default StatisticList
