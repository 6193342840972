import React, { useEffect, useState, useCallback } from 'react'
import DataList from '../../UI/DataList'
import Breadcrumbs from '../../Breadcrumbs/Breadcrumbs'
import { Button } from 'antd'
import { useDispatch } from 'react-redux'
import {
  handleSelectedVulnerability,
  handleSelectedEvent
} from '../../../store/actions'
import {
  countVulnerabilityEvents,
  listVulnerabilityEvents
} from '../../../services/api/vums'
import axios from 'axios'
import moment from 'moment'
import styles from './MonthList.module.css'

const ByEventList = ({ customerFiId, selectedVulnerability = {}, backPathClick = () => {}, showBreadcrumbs = false }) => {
  const defaultSortOrder = { field: 'date', order: 'descend' }

  const dataUpdater = (filterString, dataSetter, countSetter) => {
    return axios.all([listVulnerabilityEvents(filterString), countVulnerabilityEvents(filterString)])
      .then(axios.spread(({ data: vulnerabilityEvents }, { data: count }) => {
        countSetter(count)

        dataSetter(
          vulnerabilityEvents.map(item => ({
            key: item.guid,
            date: item.date,
            event_name: item.name
          }))
        )
      }))
  }

  const [event, setEvent] = useState({})
  const [rowKey, setRowKey] = useState(null)

  const setRowClassName = useCallback((record) => {
    return record.key === rowKey ? styles.activeRow : ''
  }, [rowKey])

  const dispatch = useDispatch()

  const renderEvent = (eventName, data) => {
    return <Button
      value={eventName}
      type="link"
      className={[styles.event_btn, 'default_color']}
      onClick={() => {
        setRowKey(data)
        setEvent(data)
      }}
    >
      {eventName}
    </Button>
  }

  const columns = [
    {
      title: 'Date',
      dataIndex: 'date',
      align: 'right',
      width: 100,
      render: function renderDate (date) { return <span>{moment(date).format('DD-MM-YY')}</span> }
    },
    {
      title: 'Event',
      dataIndex: 'event_name',
      align: 'left',
      render: renderEvent
    }
  ]

  const dataFilter = {
    'vulnerability.customer.fi_id': customerFiId,
    'vulnerability.target': 'monthly_breakdown'
  }
  if (selectedVulnerability.key) {
    const start = moment(selectedVulnerability.date).startOf('month')
    dataFilter['transactions.date_gte'] = start.toISOString()
    const end = start.clone().add(1, 'month')
    dataFilter['transactions.date_lt'] = end.toISOString()
  }

  useEffect(() => {
    dispatch(handleSelectedEvent(event))
    dispatch(handleSelectedVulnerability(selectedVulnerability))
  }, [event, selectedVulnerability, dispatch])

  return (
        <>
            {showBreadcrumbs && <div className={styles.horizontal_divider} />}
            {showBreadcrumbs && <Breadcrumbs
                month={'Months'}
                monthName={selectedVulnerability.date && moment(selectedVulnerability.date).format('MMMM')}
                handleOnClick={backPathClick}
            />}
            <DataList
              onRow={(row) => ({
                onClick: () => {
                  setEvent(row)
                  setRowKey(row.key)
                }
              })}
              className={styles.month_table}
              rowClassName={setRowClassName}
              {...{ columns, defaultSortOrder, dataUpdater, dataFilter }}
            />
        </>
  )
}

export default ByEventList
