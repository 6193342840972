import React from 'react'
import { globalErrorAlert } from '../Modal/ModalContent'

const errorIdMap = {
  'Auth.form.error.invalid': {
    field: 'email',
    message: 'Email or Password incorrect'
  },
  'Auth.form.error.confirmed': {
    field: 'email',
    message: 'Your account email is not confirmed'
  },
  'Auth.form.error.email.format': {
    field: 'email',
    message: 'You have entered an invalid email address'
  },
  'Auth.form.error.email.taken': {
    field: 'email'
  }
}

/**
 * Finds the error in response
 */
const responseErrorResolver = (response) => {
  if (!('data' in response)) {
    return []
  }
  const { data } = response

  if (!('message' in data)) {
    return []
  }
  let messages = []
  if (data.data && data.data.messages) {
    messages = data.data.messages
  } else {
    const { message } = data
    messages = message[0] && message[0].messages
    messages = messages || []
  }

  const errors = messages.map(({ id, message, field }) => {
    try {
      field = errorIdMap[id].field
      if (errorIdMap[id].message) {
        message = errorIdMap[id].message
      }
    } catch (e) {
      if (!field) {
        field = 'global'
      }
    }
    return {
      code: id,
      field,
      errors: [message]
    }
  })
  return errors
}

/**
 * Finds the response from the caught error
 * @param errorResponse
 * @returns {*[]}
 */
const errorResolver = (errorResponse) => {
  if (!('response' in errorResponse)) {
    return []
  }
  const { response } = errorResponse
  return responseErrorResolver(response)
}

const showGlobalErrors = (errors) => {
  errors.length && globalErrorAlert(errors)
}

const showBackendFormValidation = (errorResponse, formErrorHandler) => {
  const errors = errorResolver(errorResponse)
  if (!errors.length) {
    return true
  }

  const formErrors = []
  const globalErrors = []
  const errorFormatter = (error, i) => <span key={i}>{error}</span>
  errors.forEach(({ field, errors }) => {
    if (field === 'global') {
      globalErrors.push(...errors.map(errorFormatter))
    } else {
      formErrors.push({
        name: field,
        errors: errors.map(errorFormatter)
      })
    }
  })

  formErrorHandler(formErrors)
  showGlobalErrors(globalErrors)
}

export { showBackendFormValidation, responseErrorResolver, errorResolver }

export * from './code'
