import React, { useCallback, useEffect, useState } from 'react'
import qs from 'qs'
import { Table } from 'antd'
import NoDataAvailable from '../NoDataAvailable/NoDataAvailable'
import {
  createColumn,
  createSortableColumn,
  DEFAULT_PAGE_SIZE,
  DEFAULT_PAGINATION,
  SORT_DIRECTIONS_MAP
} from './utility'
import cloneDeep from 'lodash/cloneDeep'
import ColumnHeader from './ColumnHeader'

const DataList = ({
  dataFilter,
  dataUpdater,
  defaultSortOrder,
  pagination,
  columns,
  // used to manually trigger update
  lastUpdatedAt,
  ...rest
}) => {
  // pagination
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE)
  const [dataTotal, setDataTotal] = useState(DEFAULT_PAGE_SIZE)

  const onTablePaginationChange = (page, pageSize) => {
    setPage(page)
    setPageSize(pageSize)
  }

  const onTableSortChange = (sortOrder) => {
    if (sortOrder.order === undefined) {
      sortOrder = defaultSortOrder
    }
    setSortOrder(sortOrder)
  }

  const onTableChange = (pagination, filters, sorter) => {
    if (sorter.field) {
      onTableSortChange({ field: sorter.field, order: sorter.order })
    }
  }

  // switch to 1st page when search is applied
  useEffect(() => {
    setPage(1)
  }, [dataFilter])

  // sorting
  const [sortOrder, setSortOrder] = useState(defaultSortOrder)

  // List data sorting and pagination
  const createRepresentationQueryParameters = () => ({
    _limit: pageSize,
    _start: (page - 1) * pageSize,
    _sort: sortOrder.field + ':' + SORT_DIRECTIONS_MAP[sortOrder.order]
  })
  const [
    dataRepresentationQueryParameters,
    setDataRepresentationQueryParameters
  ] = useState(createRepresentationQueryParameters())
  useEffect(() => {
    setDataRepresentationQueryParameters(
      createRepresentationQueryParameters()
    )
  }, [pageSize, page, sortOrder])

  const createDataQuery = () =>
    qs.stringify(
      Object.assign({}, dataFilter, dataRepresentationQueryParameters)
    )
  const [dataQuery, setDataQuery] = useState(createDataQuery())
  useEffect(() => {
    setDataQuery(createDataQuery())
  }, [dataFilter, dataRepresentationQueryParameters])

  const [data, setData] = useState([])
  const postUpdateChecker = useCallback((data, count) => {
    if (count && data.length === 0) {
      setPage(1)
      return false
    }
    return true
  })
  useEffect(() => {
    dataUpdater(dataQuery, setData, setDataTotal, postUpdateChecker).catch(
      (err) => {
        setData([])
        setDataTotal(0)
        console.log(err)
      }
    )
  }, [dataQuery, lastUpdatedAt])

  // columns setup
  const renderColumns = columns.map((_column) => {
    const column = cloneDeep(_column)
    if (column.isSortable) {
      column.title = <ColumnHeader title={column.title} dataIndex={column.dataIndex} sortOrder={sortOrder}/>
      return createSortableColumn({ ...column, sortOrder })
    } else {
      return createColumn(column)
    }
  })

  return (
        <Table
            columns={renderColumns}
            dataSource={data}
            onChange={onTableChange}
            pagination={{
              ...DEFAULT_PAGINATION,
              current: page,
              total: dataTotal,
              onChange: onTablePaginationChange,
              ...pagination
            }}
            sticky
            size="small"
            locale={{ emptyText: <NoDataAvailable /> }}
            {...rest}
        />
  )
}

export default DataList
