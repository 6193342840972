import React from 'react'
import { Breadcrumb, Button } from 'antd'
import styles from './Breadcrumbs.module.css'

const Breadcrumbs = ({ month, monthName, handleOnClick }) => {
  // Don't render a single breadcrumb.
  // if (crumbs.length > 0) {
  //     return null;
  // }

  return (
        <>
            <Breadcrumb className={styles.breadcrumb_container}>
                <Breadcrumb.Item className={styles.breadcrumb_item}>
                    {month
                      ? (
                        <Button type='link' className={styles.breadcrumb_link} onClick={handleOnClick}>
                            { month }
                        </Button>
                        )
                      : null }
                </Breadcrumb.Item>
                <Breadcrumb.Item className={styles.breadcrumb_item}>
                    {monthName
                      ? (
                        <span className={styles.breadcrumb_link}>
                            { monthName }
                        </span>
                        )
                      : null}
                </Breadcrumb.Item>
            </Breadcrumb>
        </>
  )
}

export default Breadcrumbs
