import React from 'react'
import { Popover } from 'antd'
import styles from '../Popover/Popover.module.css'

const StatusDefinition = ({ children, placement, title = '', content, trigger }) => {
  return (
        <Popover placement={placement} title={title} content={content} trigger={trigger}>
            <span className={styles.popup_content}>{children}</span>
        </Popover>
  )
}

export default StatusDefinition
