import axios from 'axios'
import store from '../../../store/store'
import { showUnauthorizedError } from '../../../store/actions'
import { headers as defaultHeaders } from './config'
import qs from 'qs'

const CallApi = (route, data, extraHeaders) => {
  const headers = { ...defaultHeaders, ...extraHeaders }

  axios.defaults.paramsSerializer = (params) => {
    if (typeof params === 'string') {
      return params
    }
    return qs.stringify(params, { offset: 0, limit: 12 })
  }

  axios.interceptors.request.use(
    (config) => {
      // const { origin } = new URL(config.url);
      // const allowedOrigins = [window.REACT_APP_KALGERA_VUMS_API_HOST, window.REACT_APP_KALGERA_UMS_API_HOST, "http://kalgera.local"];
      const token = localStorage.getItem('token')

      if (token) {
        // if (allowedOrigins.includes(origin)) {
        config.headers.authorization = `Bearer ${token}`
      }
      return config
    },
    (error) => {
      return Promise.reject(error)
    }
  )

  axios.interceptors.response.use(null, error => {
    if (error.response &&
      error.response.data &&
      error.response.data.error &&
      error.response.data.error === 'Unauthorized' &&
      store.getState().auth.showUnauthorizedError === false
    ) {
      store.dispatch(showUnauthorizedError(true))
    }
    return Promise.reject(error)
  })

  return axios({
    ...route,
    headers,
    data
  })
}

export * from './config'

export default CallApi
