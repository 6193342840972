import React from 'react'
import { Link } from 'react-router-dom'
import { Button } from 'antd'
import { useSelector } from 'react-redux'
import binoculars from '../../assets/images/binoculars.svg'
import logo from '../../assets/images/logo.svg'
import styles from './PageNotFound.module.css'

const PageNotFound = () => {
  const { isAuthenticated } = useSelector((state) => state.auth)

  return <div className={[isAuthenticated ? styles.authenticated : styles.section].join(' ')}>
    {!isAuthenticated && <div className={styles.top_logo}>
      <img src={logo} alt='logo' />
      <div className={styles.logo_text}>Kalgera</div>
    </div>}
    <div className={styles.container}>
      <img src={binoculars} alt='binoculars'/>
      <div className={styles.text_content}>
        <p className={styles.title_first}>
          We can’t seem to find the page you are looking for.
        </p>
        <p className={styles.title_second}>
          {
            isAuthenticated
              ? 'It may be missing or there could be a typo in the url.' +
                ' To return to the main dashboard click the button below.'
              : 'It may be missing or there could be a typo in the url.' +
                ' Maybe you can find what you are looking for on our homepage.'
          }
        </p>
        <p className={styles.error_code}>Error code: 404 Page not found</p>
        <Button
          type='primary'
          className={styles.go_home_button}
        >
          <Link to="/">{isAuthenticated ? 'Return to dashboard' : 'Kalgera homepage'}</Link>
        </Button>
      </div>
    </div>
  </div>
}

export default PageNotFound
