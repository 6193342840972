import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'
import DataList from '../../UI/DataList'
import styles from '../GraphList/ApexChart.module.css'
import { useHistory } from 'react-router-dom'
import { vulnerabilityStrings } from '../../../services/vulnerability'
import { countCustomers, listCustomers } from '../../../services/api'
import { buildCustomerFilters } from '../../../services/filterBuilder'
import { handleInput } from '../../../store/actions'

const StatusList = () => {
  const defaultSortOrder = { field: 'fi_id', order: 'ascend' }
  const dispatch = useDispatch()
  const history = useHistory()

  const goToCustomer = (fiId) => {
    dispatch(handleInput(''))
    history.push('/customers/' + fiId)
  }

  // header search
  const filterTerm = useSelector(state => state.searchTerm.term)

  // filter +
  const timeline = useSelector(state => state.financialAbuseRiskFilter.timeline)
  const numberOfTransactions = useSelector(state => state.financialAbuseRiskFilter.numberOfTransactions)
  const numberOfAccounts = useSelector(state => state.financialAbuseRiskFilter.numberOfAccounts)

  // high level vulnerability status selector
  const selectedStatuses = useSelector(state => state.financialAbuseRiskFilter.selectedVulnerabilityStatuses)

  const [dataFilter, setDataFilter] = useState(
    buildCustomerFilters({ filterTerm, timeline, numberOfTransactions, numberOfAccounts, vulnerabilityStatuses: selectedStatuses })
  )
  useEffect(() => {
    setDataFilter(
      buildCustomerFilters({ filterTerm, timeline, numberOfTransactions, numberOfAccounts, vulnerabilityStatuses: selectedStatuses })
    )
  }, [filterTerm, timeline, numberOfTransactions, numberOfAccounts, selectedStatuses])

  const dataUpdater = (filterString, dataSetter, countSetter) => {
    return axios.all([listCustomers(filterString), countCustomers(filterString)])
      .then(axios.spread(({ data }, { data: count }) => {
        countSetter(count)

        if (filterTerm.length && data.length === 1 && filterTerm === data[0].fi_id) {
          goToCustomer(filterTerm)
          return
        }
        dataSetter(data.map((item) => ({
          key: item.guid,
          ...item
        })))
      }))
  }

  const renderId = (value) => {
    return <a onClick={() => goToCustomer(value)} className='default_color'>{value}</a>
  }

  const renderStatus = (value) => {
    return <span className={ value }>{vulnerabilityStrings[value]}</span>
  }

  const columns = [
    { title: 'Customer ID', dataIndex: 'fi_id', isSortable: true, align: 'center', render: renderId },
    { title: 'Age Group', dataIndex: 'age_group', isSortable: true, align: 'right', width: 150 },
    { title: 'No. Accounts', dataIndex: 'total_accounts', isSortable: true, align: 'right' },
    { title: 'Total Transactions', dataIndex: 'total_transactions', isSortable: true, align: 'right' },
    { title: 'Status', dataIndex: 'last_event_status', isSortable: true, align: 'left', render: renderStatus }
  ]

  return (
    <div className={styles.status_list_table}>
      <DataList
        onRow={(row) => ({
          onClick: () => {
            goToCustomer(row.fi_id)
          }
        })}
        {...{ columns, dataFilter, dataUpdater, defaultSortOrder }}
      />
    </div>
  )
}

export default StatusList
