import CallApi from '../apiClient'
import qs from 'qs'

const DMS_API_HOST = window.REACT_APP_KALGERA_DMS_API_HOST

const getFullUrl = path => DMS_API_HOST + path

const routes = {
  batches_list: { method: 'GET', url: getFullUrl('/batches') },
  batches_count: { method: 'GET', url: getFullUrl('/batches/count') }
}

const batchesFiles = async (queryString) => {
  return CallApi({ ...routes.batches_list, params: qs.parse(queryString) })
}

const batchesCount = async (queryString) => {
  return CallApi({ ...routes.batches_count, params: qs.parse(queryString) })
}

export { batchesFiles, batchesCount }
