import React from 'react'
import { connect } from 'react-redux'
import * as actionCreators from '../../store/actions/index'
import { Button } from '@tutorbot/ui-components'
import styles from './ButtonList.module.css'

const ButtonList = ({ view, isStatusAbsolute, absoluteButtonClickHandler, percentageButtonClickHandler }) => {
  return (
        <>
            <Button
                htmlType='button'
                className={[
                  styles.button_list,
                  view === 'order-list' ? styles.hide : styles.show,
                  isStatusAbsolute ? styles.active : ''
                ]}
                onClick={absoluteButtonClickHandler}
            >
                Absolute
            </Button>
            <Button
                htmlType='button'
                className={[
                  styles.button_list,
                  view === 'order-list' ? styles.hide : styles.show,
                  isStatusAbsolute ? '' : styles.active
                ]}
                onClick={percentageButtonClickHandler}
            >
                Percentage
            </Button>
        </>
  )
}

const mapStateToProps = state => {
  return {
    isStatusAbsolute: state.statusGraphic.isStatusAbsolute
  }
}

const mapDispatchToProps = dispatch => {
  return {
    absoluteButtonClickHandler: () => dispatch(actionCreators.toggleStatusGraphicType(true)),
    percentageButtonClickHandler: () => dispatch(actionCreators.toggleStatusGraphicType(false))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ButtonList)
