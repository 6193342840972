import React from 'react'
import styles from './Title.module.css'
import { Text } from '@tutorbot/ui-components'

const Title = ({ title }) => {
  return (
        <div className={styles.container}>
            <Text>{title}</Text>
        </div>
  )
}

export default Title
