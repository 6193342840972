import React, { useEffect, useState } from 'react'
import Input from '../../components/UI/Input/Input'
import { Button } from '@tutorbot/ui-components'
import { Form } from 'antd'
import { currentPassword, confirmNewPassword, newPassword } from '../../components/Gateway/FormInputs'
import { changeUserPassword } from '../../services/api/ums/auth/user'
import isEmpty from 'lodash/isEmpty'
import styles from './Profile.module.css'

const FormItem = Form.Item

const ChangePasswordForm = () => {
  const [changePassword, setChangePassword] = useState(false)
  const [error, setError] = useState({})
  const [form] = Form.useForm()

  const changePasswordInputs = [
    currentPassword(error, setError),
    newPassword,
    confirmNewPassword
  ]

  useEffect(() => {
    if (!changePassword) {
      return
    }
    window.scrollTo(0, document.body.scrollHeight)
  }, [changePassword])

  const handlePasswordChange = (values) => {
    setError({})

    if (Object.keys(values).length) {
      changeUserPassword(values).catch((err) =>
        setError(err.response.data.message[0].messages[0])
      )
    }
  }

  const renderError = (item) => {
    if (isEmpty(error)) {
      return
    }
    if (item.fieldName === error.field[0]) {
      return (
                <div className="ant-form-item-explain ant-form-item-explain-error">
                    <div role="alert">{error.message}</div>
                </div>
      )
    }
  }
  return (
        <>
            {changePassword && (
                <Form
                    form={form}
                    layout="vertical"
                    className={styles.changePasswordForm}
                    onFinish={handlePasswordChange}
                    validateTrigger="onBlur"
                >
                    {changePassword &&
                        changePasswordInputs.map((item, index) => (
                            <FormItem
                                name={item.fieldName}
                                label={item.name}
                                key={index}
                                rules={item.rules}
                                extra={renderError(item)}
                            >
                                <Input
                                    placeholder={item.name}
                                    type="password"
                                />
                            </FormItem>
                        ))}
                    <FormItem className={styles.changePasswordSaveButton}>
                        <Button
                            className={styles.button}
                            styles={{ marginBottom: 40 }}
                        >
                            Save new password
                        </Button>
                    </FormItem>
                    <Button
                        className={styles.button}
                        styles={{ marginBottom: 40 }}
                        onClick={() => {
                          setChangePassword(!changePassword)
                        }}
                    >
                        Cancel
                    </Button>
                </Form>
            )}
            {!changePassword && (
                <Button
                    className={styles.button}
                    styles={{ marginBottom: 40 }}
                    onClick={() => {
                      setChangePassword(!changePassword)
                    }}
                >
                    Change password
                </Button>
            )}
        </>
  )
}

export default ChangePasswordForm
