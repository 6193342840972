import React from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import { Select } from 'antd'
import styles from './ByYear.module.css'
import * as actionCreators from '../../../../../store/actions'

const filterKey = 'by_year'

const ByYear = ({ date, storeFilter }) => {
  const selectChangeHandler = date => {
    const from = date ? moment(date).startOf('year') : null
    const to = date ? moment(date).endOf('year') : null

    storeFilter(filterKey, from, to)
  }

  const years = []
  const currentYear = (new Date()).getFullYear()

  for (let i = 2000; i <= currentYear; i++) {
    years.push(
            <Select.Option key={i}>{i}</Select.Option>
    )
  }

  return (
        <Select
            value={date ? date.year() : null}
            onChange={selectChangeHandler}
            className={styles.Select}
            dropdownClassName={styles.Dropdown}
            bordered={false}
        >
            {years.reverse()}
        </Select>
  )
}

const mapStateToProps = state => {
  const from = state.financialAbuseRiskFilter.timeline.value.from
  const key = state.financialAbuseRiskFilter.timeline.key

  return {
    date: key === filterKey ? from : null
  }
}

const mapDispatchToProps = dispatch => {
  return {
    storeFilter: (key, from, to) => dispatch(actionCreators.filterTimelineRange(key, from, to))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ByYear)
