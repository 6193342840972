import * as actionTypes from '../actions/actionTypes'
import cloneDeep from 'lodash/cloneDeep'

const initialState = {
  list: []
}

const handleTransactionsList = (state = initialState, action) => {
  const clonedState = cloneDeep(state)

  switch (action.type) {
    case actionTypes.HANDLE_TRANSACTIONS_LIST:
      clonedState.list = action.value
      return clonedState
    default:
      return state
  }
}

export default handleTransactionsList
