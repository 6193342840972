import React, { useEffect } from 'react'
import { Button, Row } from 'antd'
import { useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import Header from '../../components/Header/Header'
import styles from './Customers.module.css'
import Vulnerability from '../../components/Vulnerability/Vulnerability'
import { handleSelectedVulnerability, handleSelectedEvent } from '../../store/actions'
import Breakdown from '../../components/Breakdown/Breakdown'

const Customers = () => {
  const { fi_id: fiId } = useParams()
  const dispatch = useDispatch()

  useEffect(() => {
    return () => {
      dispatch(handleSelectedEvent({}))
      dispatch(handleSelectedVulnerability({}))
    }
  }, [])

  return (
        <>
            <Header title="Financial Abuse Risk" showSearchBar showUploadButton>
                <Button
                    className={styles.button}
                    type='primary'
                >
                    { fiId }
                </Button>
            </Header>

            <div className={styles.customers_dashboard} >
                <Row gutter={16}>
                    <Vulnerability customerFiId={fiId}/>
                    <Breakdown />
                </Row>
            </div>
        </>
  )
}

export default Customers
