import { createStore, combineReducers } from 'redux'
import statusGraphic from './reducers/statusGraphic'
import searchTerm from './reducers/searchTerm'
import statisticView from './reducers/statisticView'
import financialAbuseRiskFilter from './reducers/financialAbuseRiskFilter'
import transactionsList from './reducers/transactionsList'
import selectedVulnerability from './reducers/selectedVulnerability'
import selectedEvent from './reducers/selectedEvent'
import auth from './reducers/auth'
import avatarImage from './reducers/avatarImage'
import lastUpdatedAtEvent from './reducers/lastUpdatedAtEvent'

const rootReducer = combineReducers({
  statusGraphic: statusGraphic,
  searchTerm: searchTerm,
  statisticView: statisticView,
  financialAbuseRiskFilter: financialAbuseRiskFilter,
  transactions: transactionsList,
  selectedVulnerability: selectedVulnerability,
  selectedEvent: selectedEvent,
  auth,
  avatarImage,
  lastUpdatedAtEvent
})

const store = createStore(rootReducer)

export default store
