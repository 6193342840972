import cloneDeep from 'lodash/cloneDeep'
import * as actionTypes from '../actions/actionTypes'

const initialState = {
  isAuthenticated: localStorage.getItem('token') > '',
  showUnauthorizedError: false,
  user: {},
  token: localStorage.getItem('token') || ''
}

const reducer = (state = initialState, action) => {
  const clonedState = cloneDeep(state)

  switch (action.type) {
    case actionTypes.AUTH_USER:
      clonedState.user = action.value
      break
    case actionTypes.AUTH_TOKEN:
      clonedState.isAuthenticated = action.value > ''
      clonedState.token = action.value
      break

    case actionTypes.ERROR_UNAUTHORIZED:
      clonedState.showUnauthorizedError = action.value
      break

    default:
  }

  return clonedState
}

export default reducer
