import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import 'antd/dist/antd.css'
import './assets/styles/main.css'
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css'
import App from './App'

import store from './store/store'

ReactDOM.render(
    <Provider store={store}>
      <App />
    </Provider>,
    document.getElementById('root')
)
