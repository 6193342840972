import React from 'react'
import { IconInfoCircle } from '@tutorbot/ui-components'
import styles from '../Popover/Popover.module.css'

const vulnerable = (
    <div>
        <span className={styles.popup_text}>
            <IconInfoCircle fill={'var(--primary-color)'} />
            <p>
                This indicates the customers who are currently vulnerable to financial abuse.
            </p>
        </span>
    </div>
)

const potentiallyVulnerable = (
    <div>
        <span className={styles.popup_text}>
            <IconInfoCircle fill={'var(--primary-color)'} />
            <p>
                This indicates the customers who are potentially vulnerable to financial abuse.
            </p>
        </span>
    </div>
)

const notVulnerable = (
    <div>
        <span className={styles.popup_text}>
            <IconInfoCircle fill={'var(--primary-color)'} />
            <p>
                This indicates the customers who are not currently vulnerable to financial abuse.
            </p>
        </span>
    </div>
)

export { vulnerable, potentiallyVulnerable, notVulnerable }
