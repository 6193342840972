import * as actionTypes from '../actions/actionTypes'
import cloneDeep from 'lodash/cloneDeep'

const initialState = {
  event: {}
}

const handleSelectedEvent = (state = initialState, action) => {
  const clonedState = cloneDeep(state)

  switch (action.type) {
    case actionTypes.HANDLE_SELECTED_EVENT:
      clonedState.event = action.value
      return clonedState
    default:
      return state
  }
}

export default handleSelectedEvent
