import React, { useEffect } from 'react'
import PageTitle from '../../components/PageTitle/PageTitle'
import Input from '../../components/UI/Input/Input'
import { CardContainer, Button } from '@tutorbot/ui-components'
import { Form, Divider } from 'antd'
import {
  firstName,
  lastName,
  jobTitle,
  company,
  emailAddress
} from '../../components/Gateway/FormInputs'
import { useSelector, useDispatch } from 'react-redux'
import { updateUserProfile } from '../../services/api'
import ChangePasswordForm from './ChangePasswordForm'
import UploadAvatarForm from './UploadAvatarForm'
import get from 'lodash/get'
import { setAuthenticatedUser } from '../../store/actions'
import styles from './Profile.module.css'

const FormItem = Form.Item

const Profile = () => {
  const [form] = Form.useForm()

  const formItemsList = [
    firstName,
    lastName,
    jobTitle,
    company,
    emailAddress
  ]
  const dispatch = useDispatch()

  const user = useSelector((state) => state.auth.user)

  const handleProfileUpdate = (values) => {
    updateUserProfile(values)
      .then((resultUser) => {
        formItemsList.forEach((item) => {
          if (item.fieldName !== 'email') {
            dispatch(setAuthenticatedUser(get(resultUser, 'data')))
          }
        })
      })
      .catch((err) => console.log('err', err))
  }

  useEffect(() => {
    form.setFieldsValue(user)
  }, [form, user])

  return (
        <div className={styles.container}>
            <PageTitle title="Your Details" />
            <CardContainer>
                <Form
                    form={form}
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 24 }}
                    layout="vertical"
                    className="form"
                    onFinish={handleProfileUpdate}
                    validateTrigger="onBlur"
                >
                    {formItemsList.map((item, index) => (
                        <FormItem
                            key={index}
                            name={item.fieldName}
                            label={item.name}
                            rules={item.rules}
                        >
                            <Input
                                placeholder={item.name}
                                disabled={item.fieldName === 'email'}
                            />
                        </FormItem>
                    ))}
                    <FormItem>
                        <Button className={styles.button}>Save changes</Button>
                    </FormItem>
                    <Divider />
                </Form>
                <div className={styles.uploadForm}>
                    <UploadAvatarForm />
                </div>
                <Divider />
                <ChangePasswordForm />
            </CardContainer>
        </div>
  )
}

export default Profile
