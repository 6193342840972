import cloneDeep from 'lodash/cloneDeep'
import * as actionTypes from '../actions/actionTypes'
import { vulnerabilityOrder } from '../../services/vulnerability'

const initialState = {
  timeline: {
    key: null,
    value: {
      from: null,
      to: null
    }
  },
  numberOfTransactions: {
    key: null,
    value: {
      from: null,
      to: null
    }
  },
  numberOfAccounts: {
    key: null,
    value: {
      from: null,
      to: null
    }
  },
  selectedVulnerabilityStatuses: vulnerabilityOrder
}

const reducer = (state = initialState, action) => {
  const clonedState = cloneDeep(state)

  switch (action.type) {
    case actionTypes.FARF_TIMELINE_RESET:
      clonedState.timeline.key = action.key
      clonedState.timeline.value = action.value
      break

    case actionTypes.FARF_TIMELINE_RANGE:
      clonedState.timeline.key = action.key
      clonedState.timeline.value = action.value
      break

    case actionTypes.FARF_NUMBER_OF_TRANSACTIONS_RESET:
      clonedState.numberOfTransactions.key = action.key
      clonedState.numberOfTransactions.value = action.value
      break

    case actionTypes.FARF_NUMBER_OF_TRANSACTIONS_RANGE:
      clonedState.numberOfTransactions.key = action.key
      clonedState.numberOfTransactions.value = action.value
      break

    case actionTypes.FARF_NUMBER_OF_ACCOUNTS_RESET:
      clonedState.numberOfAccounts.key = action.key
      clonedState.numberOfAccounts.value = action.value
      break

    case actionTypes.FARF_NUMBER_OF_ACCOUNTS_RANGE:
      clonedState.numberOfAccounts.key = action.key
      clonedState.numberOfAccounts.value = action.value
      break

    case actionTypes.FARF_SELECTED_VULNERABILITY_STATUSES:
      clonedState.selectedVulnerabilityStatuses = action.value
      break

    default:
  }

  return clonedState
}

export default reducer
