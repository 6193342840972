import React from 'react'
import UploadData from '../FileUpload/FileUpload'
import { Divider } from '@tutorbot/ui-components'
import styles from './Header.module.css'
import Title from '../UI/Title/Title'
import SearchInput from '../UI/SearchInput/SearchInput'

const Header = ({ title, showSearchBar = false, showUploadButton = false, children }) => {
  return (
        <>
            <div className={styles.header}>
                <Title title={title} />
                <div className={styles.header_option}>
                    {showSearchBar && <SearchInput />}
                    {showUploadButton && <UploadData />}
                </div>
            </div>

            {children}

            <Divider type='horizontal' />
        </>
  )
}

export default Header
