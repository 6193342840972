import React from 'react'
import Header from '../../components/Header/Header'
import BatchUploadHistory from '../../components/BatchUploadHistory/BatchUploadHistory'

const BatchManagement = () => {
  return (
        <>
            <Header title="Batch Management" />
            <BatchUploadHistory />
        </>
  )
}

export default BatchManagement
