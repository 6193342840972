import React, { useState } from 'react'
import ForgotPasswordConfirm from './ForgotPasswordConfirm'
import ForgotPassword from './ForgotPassword'

const ForgotPasswordWrapper = () => {
  const [identity, setIdentity] = useState('')

  if (identity === '') {
    return <ForgotPassword setIdentity={setIdentity} />
  } else {
    return <ForgotPasswordConfirm identity={identity} />
  }
}

export default ForgotPasswordWrapper
