export const TOGGLE_STATUS_GRAPHIC_TYPE = 'TOGGLE_STATUS_GRAPHIC_TYPE'
export const HANDLE_INPUT = 'HANDLE_INPUT'
export const HANDLE_VIEW = 'HANDLE_VIEW'
export const HANDLE_TRANSACTIONS_LIST = 'HANDLE_TRANSACTIONS_LIST'
export const HANDLE_SELECTED_VULNERABILITY = 'HANDLE_SELECTED_VULNERABILITY'
export const HANDLE_SELECTED_EVENT = 'HANDLE_SELECTED_EVENT'
export const DATA_UPLOAD_HISTORY_LIST = 'DATA_UPLOAD_HISTORY_LIST'

// FINANCIAL ABUSE RISK FILTERS (FARF)
export const FARF_TIMELINE_RESET = 'FARF_TIMELINE_RESET'
export const FARF_TIMELINE_RANGE = 'FARF_TIMELINE_RANGE'

export const FARF_NUMBER_OF_TRANSACTIONS_RESET =
    'FARF_NUMBER_OF_TRANSACTIONS_RESET'
export const FARF_NUMBER_OF_TRANSACTIONS_RANGE =
    'FARF_NUMBER_OF_TRANSACTIONS_RANGE'

export const FARF_NUMBER_OF_ACCOUNTS_RESET = 'FARF_NUMBER_OF_ACCOUNTS_RESET'
export const FARF_NUMBER_OF_ACCOUNTS_RANGE = 'FARF_NUMBER_OF_ACCOUNTS_RANGE'

export const FARF_SELECTED_VULNERABILITY_STATUSES =
    'FARF_SELECTED_VULNERABILITY_STATUSES'

export const AUTH_USER = 'AUTH_USER'
export const AUTH_TOKEN = 'AUTH_TOKEN'

export const AVATAR_IMAGE_SET = 'AVATAR_IMAGE_SET'

// GLOBAL ERRORS
export const ERROR_UNAUTHORIZED = 'ERROR_UNAUTHORIZED'

export const LIST_LAST_UPDATED_EVENT = 'LIST_LAST_UPDATED_EVENT'
