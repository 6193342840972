import React from 'react'
import { Button, Text } from '@tutorbot/ui-components'
import styles from '../Registration.module.css'
import { requestPasswordReset } from '../../../services/api'

const ForgotPasswordConfirm = ({ identity }) => {
  return (
        <div className={styles.forgotPasswordContainer}>
            <Text level={1} semiBold>
                Forgot Password
            </Text>
            <>
                <p className={styles.text}>
                    We have sent you a link to reset your password.
                </p>
                <p className={styles.text}>
                    If you don’t see the email within 10 minutes, please click
                    the button below to re-send it.
                </p>
            </>
            <div className={styles.buttonWrapper}>
                <Button
                    className={styles.button}
                    onClick={() => requestPasswordReset(identity)}
                >
                    Re-send reset link
                </Button>
            </div>
        </div>
  )
}
export default ForgotPasswordConfirm
