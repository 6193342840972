import _ from 'lodash'
import * as actionTypes from '../actions/actionTypes'
import moment from 'moment'

const initialState = {
  listLastUpdatedAt: moment().valueOf()
}

const reducer = (state = initialState, action) => {
  const clonedState = _.cloneDeep(state)

  switch (action.type) {
    case actionTypes.LIST_LAST_UPDATED_EVENT:
      clonedState.listLastUpdatedAt = action.value
      break
    default:
  }

  return clonedState
}

export default reducer
