import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import { Select } from 'antd'
import styles from './ByMonth.module.css'
import * as actionCreators from '../../../../../store/actions'

const filterKey = 'by_month'

const ByMonth = ({ date, storeFilter }) => {
  const [selectedYear, setSelectedYear] = useState(null)
  const [selectedMonth, setSelectedMonth] = useState(null)

  useEffect(() => {
    if (date) {
      setSelectedYear(date.year())
      setSelectedMonth(date.month() + 1)
    }
  }, [date])

  useEffect(() => {
    if (selectedYear && selectedMonth) {
      const from = moment().year(selectedYear).month(selectedMonth - 1).startOf('month')
      const to = from.clone().endOf('month')

      storeFilter(filterKey, from, to)
    }
  }, [selectedYear, selectedMonth, storeFilter])

  const years = []
  const months = Array.apply(0, Array(12)).map((_, i) => moment().month(i).format('MMMM'))
  const currentYear = (new Date()).getFullYear()

  for (let i = 2000; i <= currentYear; i++) {
    years.push(
            <Select.Option key={i}>{i}</Select.Option>
    )
  }

  return (
        <div className={styles.InputNumberWrapper}>
            <Select
                value={selectedYear}
                onChange={setSelectedYear}
                className={styles.Select}
                dropdownClassName={styles.Dropdown}
                bordered={false}
                dropdownMatchSelectWidth={224}
                placeholder="Year"
            >
                {years.reverse()}
            </Select>
            <Select
                value={selectedMonth}
                onChange={setSelectedMonth}
                className={styles.Select}
                dropdownClassName={styles.Dropdown}
                bordered={false}
                placeholder="Month"
                dropdownMatchSelectWidth={224}
            >
                {
                    months.map((month, i) => (
                        <Select.Option key={i + 1} >{month}</Select.Option>
                    ))
                }
            </Select>
        </div>
  )
}

const mapStateToProps = state => {
  const from = state.financialAbuseRiskFilter.timeline.value.from
  const key = state.financialAbuseRiskFilter.timeline.key

  return {
    date: key === filterKey ? from : null
  }
}

const mapDispatchToProps = dispatch => {
  return {
    storeFilter: (key, from, to) => dispatch(actionCreators.filterTimelineRange(key, from, to))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ByMonth)
