import React from 'react'
import Header from '../components/Header/Header'
import StatisticList from '../components/Statistic/StatisticList/StatisticList'
import Statistic from '../components/Statistic/Statistic'
import ButtonGroup from '../components/ButtonGroup/ButtonGroup'
import FinancialAbuseRiskFilter from '../components/Filters/FinancialAbuseRiskFilter/FinancialAbuseRiskFilter'

const Home = () => {
  return (
        <>
          <Header title="Financial Abuse Risk" showSearchBar showUploadButton>
              <ButtonGroup />
              <FinancialAbuseRiskFilter />
          </Header>

          <StatisticList />

          <Statistic />
        </>
  )
}

export default Home
