import React from 'react'
import axios from 'axios'
import moment from 'moment'
import DataList from '../UI/DataList'
import { CardContainer } from '@tutorbot/ui-components'
import { batchesFiles, batchesCount } from '../../services/api'
import styles from './BatchUploadHistory.module.css'

const getStatusLabel = status => {
  const statusMap = {
    // In progress statuses
    partitioning_customers: 'Analysing...',
    processing: 'Analysing...',
    analysing: 'Analysing...',
    processed: 'Success',
    uploading: 'Uploading...',

    // waiting statuses
    partitioned: 'Active',
    queued: 'Active',

    // Error statuses
    status_conflict_1: 'Unsuccessful / Unexpected Status',
    error_anomaly: 'Anomaly Detection Error',
    corrupted_file: 'Unsuccessful / Invalid File Format',
    customer_id_not_found: 'Unsuccessful / Customer Id missing',
    error_analysing: 'Error When Analyzing',
    not_enough_data: 'Not Enough Customer Data'
  }
  return statusMap[status]
}

const BatchUploadHistory = () => {
  const defaultSortOrder = { field: 'created_at', order: 'descend' }
  const sortDirections = ['descend', 'ascend']

  const dataUpdater = (filterString, dataSetter, countSetter, postUpdateChecker) => {
    return axios.all([batchesFiles(filterString), batchesCount(filterString)])
      .then(axios.spread(async ({ data: files }, { data: count }) => {
        if (postUpdateChecker(files, count) === false) {
          return
        }

        countSetter(count)
        dataSetter(files)
      }))
  }

  const renderDate = (value) => (
    <span>{moment(value).format('DD-MM-YY HH:mm:ss')}</span>
  )

  const renderStatus = (value) => {
    let style = 'warning'

    switch (value) {
      case 'partitioning_customers':
      case 'partitioned':
      case 'processed':
        style = 'success'
        break

      case 'uploading':
      case 'queued':
      case 'processing':
      case 'not_enough_data':
        style = 'warning'
        break

      case 'analysing':
      case 'status_conflict_1':
      case 'corrupted_file':
      case 'customer_id_not_found':
      case 'error_anomaly':
      case 'error_analysing':
        style = 'danger'
        break

      default:
    }

    return (<span className={styles[style]}>{getStatusLabel(value)}</span>)
  }

  const columns = [
    {
      title: 'Date',
      dataIndex: 'created_at',
      isSortable: true,
      sortDirections,
      render: renderDate
    },
    {
      title: 'Name',
      dataIndex: 'name',
      isSortable: true
    },
    {
      title: 'Status',
      dataIndex: 'status',
      isSortable: true,
      render: renderStatus
    },
    {
      title: 'Requested by',
      dataIndex: 'created_by'
    }
  ]

  return (
    <div className={styles.ContainerWrapper}>
      <CardContainer>
        <div className={styles.list_top}>
          <h1 className={styles.title}>Request history</h1>
        </div>
        <DataList
          rowKey={record => record.guid}
          {...{ columns, defaultSortOrder, dataUpdater }}
        />
      </CardContainer>
    </div>
  )
}

export default BatchUploadHistory
