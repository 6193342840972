import React from 'react'
import moment from 'moment'
import SpecificRange from '../filters/Timeline/SpecificRange'
import ByDay from '../filters/Timeline/ByDay'
import ByWeek from '../filters/Timeline/ByWeek'
import ByMonth from '../filters/Timeline/ByMonth'
import ByYear from '../filters/Timeline/ByYear'

export const TimelineFilter = [
  {
    value: 'year_to_date',
    name: 'Year to date',
    onSelect: (e, storeFilter) => {
      const filterKey = 'year_to_date'
      const from = moment().startOf('year')
      const to = moment()

      storeFilter(filterKey, from, to)
    }
  }, {
    value: 'specific_range',
    name: 'Specific range',
    filter: <SpecificRange />
  }, {
    value: 'by_day',
    name: 'By day',
    filter: <ByDay />
  }, {
    value: 'by_week',
    name: 'By week',
    filter: <ByWeek />
  }, {
    value: 'by_month',
    name: 'By month',
    filter: <ByMonth />
  }, {
    value: 'by_year',
    name: 'By year',
    filter: <ByYear />
  }
]
