import React from 'react'
import { UpOutlined, DownOutlined } from '@ant-design/icons'
import styles from './styles.module.css'

const ColumnHeader = ({ title, dataIndex, sortOrder }) => {
  const activeColor = 'var(--primary-color)'
  return (
              <div className={styles.tableTitleWrapper}>
                  {title}
                  <div className={styles.sortingIconsWrapper}>
                      <UpOutlined
                          className={styles.arrowIcon}
                          style={{
                            color:
                                  sortOrder.order === 'ascend' &&
                                  dataIndex === sortOrder.field &&
                                  activeColor
                          }}
                      />
                      <DownOutlined
                          className={styles.arrowIcon}
                          style={{
                            color:
                                  sortOrder.order === 'descend' &&
                                  dataIndex === sortOrder.field &&
                                  activeColor
                          }}
                      />
                  </div>
              </div>
  )
}

export default ColumnHeader
