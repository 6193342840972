import CallApi from '../apiClient'

export * from './auth'

const UMS_API_HOST = window.REACT_APP_KALGERA_UMS_API_HOST

const getFullUrl = path => UMS_API_HOST + path

const routes = {
  user_list: { method: 'GET', url: getFullUrl('/users') },
  user_invite_get: { method: 'GET', url: getFullUrl('/users-invites/:confirmationToken') },
  user_invite_confirm: { method: 'POST', url: getFullUrl('/users-invites/confirmation') }
}

const listUsers = async (params) => {
  return CallApi({ ...routes.user_list, params })
}

const getUsersInvites = async (confirmationToken) => {
  const route = routes.user_invite_get
  return CallApi({ ...route, url: route.url.replace(':confirmationToken', confirmationToken) })
}

const confirmUsersInvites = async (params) => {
  return CallApi(routes.user_invite_confirm, params)
}

export {
  listUsers,
  getUsersInvites,
  confirmUsersInvites
}
