import React, { useState, useEffect } from 'react'
import { Form } from 'antd'
import { Button } from '@tutorbot/ui-components'
import Input from '../../components/UI/Input/Input'
import { confirmUsersInvites, getUsersInvites, register } from '../../services/api'
import {
  firstName,
  lastName,
  jobTitle,
  emailAddress,
  password,
  confirmPassword
} from '../../components/Gateway/FormInputs'
import { setAuthenticatedUser } from '../../store/actions'
import { useDispatch } from 'react-redux'
import styles from '../../components/Gateway/Registration.module.css'
import { showBackendFormValidation } from '../../components/UI/Error'

const SignupForm = ({ confirmationToken, setVerifyEmail }) => {
  const [invitedUser, setInvitedUser] = useState({})
  const [form] = Form.useForm()
  const dispatch = useDispatch()

  useEffect(() => {
    if (!confirmationToken) {
      return
    }
    getUsersInvites(confirmationToken)
      .then(({ data }) => {
        const user = {
          email: data.email
        }
        setInvitedUser(user)
        form.setFieldsValue(user)
      })
  }, [confirmationToken])

  const signupFormItems = [
    firstName,
    lastName,
    jobTitle,
    emailAddress,
    password,
    confirmPassword
  ]

  const onFinish = (values) => {
    if (invitedUser.email) {
      confirmUsersInvites({ ...values, confirmationToken })
        .then(({ data }) => {
          dispatch(setAuthenticatedUser(data))
          setVerifyEmail(2)
        })
    } else {
      register(values)
        .then(({ data }) => {
          dispatch(setAuthenticatedUser(data.user))
          setVerifyEmail(1)
        })
        .catch((error) => {
          showBackendFormValidation(error, (formErrors = []) => {
            // todo check for fields existence
            formErrors.length && form.setFields(formErrors)
          })
        })
    }
  }

  return (
        <Form
            form={form}
            layout="vertical"
            validateTrigger="onBlur"
            className="form"
            onFinish={onFinish}
        >
            {signupFormItems.map((item, index) => (
                <Form.Item
                    key={index}
                    name={item.fieldName}
                    label={item.name}
                    rules={item.rules}
                >
                    <Input
                        placeholder={item.placeholder}
                        type={item.type}
                        prefix={item.icon}
                        disabled={Object.prototype.hasOwnProperty.call(invitedUser, item.fieldName)}
                    />
                </Form.Item>
            ))}
            <Form.Item>
                <Button className={styles.button}>Sign up</Button>
            </Form.Item>
        </Form>
  )
}
export default SignupForm
