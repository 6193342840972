import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CardContainer, Divider } from '@tutorbot/ui-components'

import styles from './Statistic.module.css'
import ApexChart from './GraphList/ApexChart'
import StatusList from './StatusList/StatusList'
import ButtonList from '../ButtonList/ButtonList'
import ToggleButton from '../UI/ToggleButton/ToggleButton'
import { handleStatisticView } from '../../store/actions'

const Statistic = () => {
  const dispatch = useDispatch()
  const changeCurrentView = (view) => dispatch(handleStatisticView(view))
  const currentView = useSelector(state => state.statisticView.view)
  const [dateEnd, setDateEnd] = useState(null)

  const renderViews = () => {
    switch (currentView) {
      case 'graphic':
        return <ApexChart setDateEnd={setDateEnd} />
      case 'order-list':
        return <StatusList />
      default:
        return <ApexChart />
    }
  }

  return (
    <div className={styles.list}>
      <CardContainer>
        <div className={styles.list_top}>
          <h1 className={styles.title}>Status</h1>&nbsp;&nbsp;
          {dateEnd ? <p>{dateEnd}</p> : ''}
          <div className={styles.button_list}>
            <ButtonList view={currentView} />
          </div>
          <div>
            <ToggleButton view={changeCurrentView} currentView={currentView} />
          </div>
        </div>
        <Divider type={'horizontal'} />

        { renderViews() }

      </CardContainer>
    </div>
  )
}

export default Statistic
