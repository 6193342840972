import React from 'react'
import { useSelector } from 'react-redux'
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'
import Layout from '../hoc/Layout/Layout'

import Home from '../pages/Home'
import Profile from '../pages/Profile/Profile'
import Registration from '../pages/Registration/Registration'
import Customers from '../pages/Customers/Customers'
import ResetPassword from '../pages/ResetPassword/ResetPassword'
import DataManagement from '../pages/DataManagement/DataManagement'
import BatchManagement from '../pages/BatchManagement/BatchManagement'
import IdleTimerContainer from '../helpers/IdleTimerContainer/IdleTimerContainer'
import PageNotFound from '../helpers/PageNotFound/PageNotFound'

export const routes = {
  home: '/',
  profile: '/profile',
  registration: '/registration',
  dashboard: '/customers/:fi_id',
  dataManagement: '/data-management',
  batchManagement: '/batch-management',
  resetPassword: '/reset-password'
}

const Router = () => {
  const PrivateRoute = ({ children, ...rest }) => {
    const { isAuthenticated } = useSelector(state => state.auth)

    return <Route {...rest}>
      {isAuthenticated ? children : <Redirect to={{ pathname: '/registration', search: '?action=login' }}/>}
    </Route>
  }

  return (
    <BrowserRouter>
      <IdleTimerContainer>
        <Layout>
          <Switch>
            {/* Home page */}
            <PrivateRoute exact path={routes.home}>
              <Home/>
            </PrivateRoute>

            {/* Registration page */}
            <Route path={routes.registration}>
              <Registration/>
            </Route>

            {/* User profile page */}
            <PrivateRoute exact path={routes.profile}>
              <Profile />
            </PrivateRoute>

            {/* Reset password page */}
            <Route exact path={routes.resetPassword}>
              <ResetPassword/>
            </Route>

            {/* Customers page */}
            <PrivateRoute exact path={routes.dashboard}>
              <Customers/>
            </PrivateRoute>

            {/* Data management page */}
            <PrivateRoute exact path={routes.dataManagement}>
              <DataManagement/>
            </PrivateRoute>
            <PrivateRoute exact path={routes.batchManagement}>
              <BatchManagement/>
            </PrivateRoute>
            {/* Page not found */}
            <Route component={PageNotFound} />
          </Switch>
        </Layout>
      </IdleTimerContainer>
    </BrowserRouter>
  )
}

export default Router
