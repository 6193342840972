import React from 'react'
import { connect } from 'react-redux'
import { DatePicker, ConfigProvider } from 'antd'
import styles from './SpecificRange.module.css'
import * as actionCreators from '../../../../../store/actions'
import 'moment/locale/en-gb'
import locale from 'antd/es/locale/en_GB'
import moment from 'moment'

moment.updateLocale('en-gb', {
  week: {
    dow: 1
  }
})

const filterKey = 'specific_range'

const ByDay = ({ from, to, storeFilter }) => {
  const datePickerChangeHandler = dates => {
    if (!dates || dates.length !== 2) {
      return
    }

    const from = dates[0] ? dates[0].clone().startOf('day') : null
    const to = dates[1] ? dates[1].clone().endOf('day') : null
    storeFilter(filterKey, from, to)
  }

  return (
        <ConfigProvider locale={locale}>
            <DatePicker.RangePicker
                onChange={datePickerChangeHandler}
                value={[from, to]}
                className={styles.DatePicker}
                placeholder={['From', 'To']}
                separator=" "
                suffixIcon={<></>}
                dropdownClassName={styles.Dropdown}
                showToday={false}
            />
        </ConfigProvider>
  )
}

const mapStateToProps = state => {
  const from = state.financialAbuseRiskFilter.timeline.value.from
  const to = state.financialAbuseRiskFilter.timeline.value.to
  const key = state.financialAbuseRiskFilter.timeline.key

  return {
    from: key === filterKey ? from : null,
    to: key === filterKey ? to : null
  }
}

const mapDispatchToProps = dispatch => {
  return {
    storeFilter: (key, from, to) => dispatch(actionCreators.filterTimelineRange(key, from, to))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ByDay)
