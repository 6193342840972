import React, { useEffect } from 'react'
import { Form } from 'antd'
import { useHistory } from 'react-router-dom'
import { Button } from '@tutorbot/ui-components'
import Input from '../../components/UI/Input/Input'
import { emailAddress, password } from '../../components/Gateway/FormInputs'
import { login, resendVerification } from '../../services/api'
import {
  setAuthenticatedUser,
  setAuthenticationToken
} from '../../store/actions'
import { useDispatch } from 'react-redux'
import { routes } from '../../router/Router'
import styles from '../../components/Gateway/Registration.module.css'
import { showBackendFormValidation, CODE_NOT_CONFIRMED, errorResolver } from '../../components/UI/Error'

const loginFormItems = [emailAddress, { ...password, rules: [] }]

const LoginForm = ({ setActiveKey, setVerifyEmail }) => {
  const [form] = Form.useForm()
  const dispatch = useDispatch()
  const history = useHistory()

  useEffect(() => {
    dispatch(setAuthenticatedUser({}))
    dispatch(setAuthenticationToken(''))
  }, [dispatch])

  let userEmail
  const handleSignIn = (values) => {
    userEmail = values.email
    login(values)
      .then(({ data: { jwt, user } }) => {
        dispatch(setAuthenticatedUser(user))
        dispatch(setAuthenticationToken(jwt))

        history.push(routes.home)
      })
      .catch((error) => {
        const formattedErrors = errorResolver(error)
        const isNotVerified = undefined !== formattedErrors.find(({ code }) => code === CODE_NOT_CONFIRMED)

        if (isNotVerified) {
          dispatch(setAuthenticatedUser({ email: userEmail }))
          resendVerification({ email: userEmail })
            .then(({ data }) => {
              console.log('Your user received an email', data.sent)
            })
          setVerifyEmail(1)
        }

        // registers
        showBackendFormValidation(error, (formErrors = []) => {
          // todo check for fields existence
          formErrors.length && form.setFields(formErrors)
        })
      })
  }

  return (
      <Form
          form={form}
          layout="vertical"
          initialValues={{}}
          className="form"
          validateTrigger="onBlur"
          onFinish={handleSignIn}
      >
          {loginFormItems.map((item, index) => (
              <Form.Item
                  key={index}
                  name={item.fieldName}
                  label={item.name}
                  rules={item.rules}
              >
                  <Input
                      placeholder={item.placeholder}
                      type={item.type}
                      prefix={item.icon}
                  />
              </Form.Item>
          ))}
          <Form.Item>
              <Button className={styles.button}>Log in</Button>
          </Form.Item>
          <p
              className={styles.forgotPassword}
              onClick={() => setActiveKey(null)}
          >
              Forgot password?
          </p>
      </Form>
  )
}
export default LoginForm
