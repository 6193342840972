import React, { useState, useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { UserOutlined, SettingOutlined } from '@ant-design/icons'
import {
  Avatar,
  IconPieChart,
  IconPulse,
  IconMessageSuare,
  IconArrowDown
} from '@tutorbot/ui-components'
import logo from '../../assets/images/logo.svg'
import { useSelector, useDispatch } from 'react-redux'
import { getMe, getImage } from '../../services/api/ums/auth'
import { setAuthenticatedUser, avatarImage } from '../../store/actions'
import get from 'lodash/get'
import styles from './Sidebar.module.css'
import { logOutDialog } from '../UI/Modal/ModalContent'

const Sidebar = () => {
  const history = useHistory()
  const termAndPoliceLink = window.TERMS_AND_POLICE_LINK

  const defaultUser = {
    profilePicture: 'https://www.w3schools.com/howto/img_avatar.png'
  }
  const defaultUserMenu = [
    {
      label: 'Log in',
      link: '/registration?action=login'
    }
  ]
  const [isMenuCollapsed, setIsMenuCollapsed] = useState(false)
  const [subMenu, setSubMenu] = useState(null)
  const [isUserDropdownVisible, setIsUserDropdownVisible] = useState(false)
  const [activeMenuItem, setActiveMenuItem] = useState(null)
  const [activeSubMenuItem, setActiveSubMenuItem] = useState(null)
  const [userData, setUserData] = useState(defaultUser)
  const [userMenu, setUserMenu] = useState(defaultUserMenu)

  const { isAuthenticated, user } = useSelector((state) => state.auth)

  const avatar = useSelector((state) => state.avatarImage.avatar) || defaultUser.profilePicture

  const handleLogOut = () => {
    history.push('/registration?action=login')
  }

  useEffect(() => {
    getImage().then((image) => {
      if (get(image, 'data.url')) { return dispatch(avatarImage(get(image, 'data.url'))) }
    })
  }, [])

  useEffect(() => {
    if (isAuthenticated && user.role) {
      setUserData(
        Object.assign({}, userData, {
          name: `${user.first_name} ${user.last_name}`,
          position: user.role.name,
          profilePicture: avatar
        })
      )

      setUserMenu([
        {
          label: 'Your profile',
          icon: <UserOutlined />,
          click: (e) => {
            const menuItem = {
              label: 'Your profile',
              icon: <IconPieChart size={'sm'} />,
              subMenu: [
                {
                  label: 'Your details',
                  link: '/profile'
                }
              ]
            }

            setIsUserDropdownVisible(false)
            setIsMenuCollapsed(true)
            setSubMenu(menuItem)
            setActiveMenuItem(null)
            setActiveSubMenuItem('0' + menuItem.subMenu[0].label)
            menuItem.subMenu[0].link && history.push(menuItem.subMenu[0].link)
          }
        },
        {
          label: 'Settings',
          link: 'settings',
          icon: <SettingOutlined />
        },
        {
          label: 'Terms & policies',
          url: termAndPoliceLink
        },
        {
          label: 'Log out',
          click: () => logOutDialog(handleLogOut)
        }
      ])
    } else {
      setUserData(defaultUser)
      setUserMenu(defaultUserMenu)
    }
  }, [isAuthenticated, user, avatar])

  const dispatch = useDispatch()

  useEffect(() => {
    if (!userData.name) {
      getMe().then((res) => {
        res && dispatch(setAuthenticatedUser(res.data))
      })
    }
  }, [])

  const menu = [
    {
      label: 'Products',
      icon: <IconPieChart size={'sm'} />,
      subMenu: [
        {
          label: 'Financial abuse risk',
          link: '/'
        }
      ]
    },
    {
      label: 'Data',
      icon: <IconPulse size={'sm'} fill={'var(--black-60)'} />,
      subMenu: [
        {
          label: 'Data management',
          link: '/data-management'
        },
        {
          label: 'Batch Requests',
          link: '/batch-management'
        },
        {
          label: 'Documentation',
          link: '/'
        }
      ]
    },
    {
      label: 'Support',
      icon: <IconMessageSuare size={'sm'} />
    }
  ]

  const renderMenu = (menu) =>
    menu.map((item, i) => (
            <li
                key={i}
                className={[
                  styles.MenuItem,
                  activeMenuItem === i + item.label ? styles.active : ' '
                ].join(' ')}
                onClick={(e) => menuItemClickHandler(e, i, item)}
            >
                <div className={styles.MenuIcon}>{item.icon}</div>
                <div className={styles.MenuLabel}>{item.label}</div>
            </li>
    ))

  const renderUserMenu = (menu) =>
    menu.map((item, i) => (
      <li
        key={i}
        onClick={
          typeof item.click === 'function'
            ? (e) => item.click(e)
            : () => {}
        }
      >
        {item.url > ''
          ? <a href={item.url} target='_blank' rel="noreferrer">{item.icon} {item.label}</a>
          : <Link
            to={item.link ? item.link : '#'}
            target={item.target ? item.target : ''}
          >
            {item.icon} {item.label}
          </Link>
        }
      </li>
    ))

  const menuItemClickHandler = (event, index, item) => {
    setIsMenuCollapsed(true)
    setActiveMenuItem(index + item.label)

    if (item.subMenu && item.subMenu[0]) {
      setActiveSubMenuItem('0' + item.subMenu[0].label)

      item.subMenu[0].link && history.push(item.subMenu[0].link)
    }
    if (typeof item.click === 'function') {
      item.click(event)
    }
    setSubMenu(item)
  }

  const renderSubMenu = () => {
    if (!subMenu || !subMenu.subMenu) {
      return
    }

    const submenuItems = subMenu.subMenu.map((item, i) => (
            <li
                key={i}
                className={[
                  styles.MenuItem,
                  activeSubMenuItem === i + item.label ? styles.active : ' '
                ].join(' ')}
                onClick={(e) => setActiveSubMenuItem(i + item.label)}
            >
              {item.url > ''
                ? <a href={item.url} target='_blank' rel="noreferrer">{item.label}</a>
                : <Link to={item.link}>{item.label}</Link>
              }
            </li>
    ))

    return (
            <ul>
                <li className={styles.SubMenuHeader}>{subMenu.label}</li>
                {submenuItems}
            </ul>
    )
  }

  return (
        <nav
            className={[
              styles.Sidebar,
              isMenuCollapsed && (!subMenu || !subMenu.subMenu)
                ? styles.isCollapsed
                : ''
            ].join(' ')}
        >
            <section
                className={[
                  styles.MenuWrapper,
                  isMenuCollapsed ? styles.isCollapsed : ''
                ].join(' ')}
            >
                <ul className={styles.Menu}>
                    <li className={[styles.MenuItem, styles.Brand].join(' ')}>
                        <div
                            className={[styles.MenuIcon, styles.BrandIcon].join(
                              ' '
                            )}
                        >
                            <Link to="/">
                                <img src={logo} alt="Kalgera" />
                            </Link>
                        </div>
                        <div className={styles.MenuLabel}>
                            <Link to="/">Kalgera</Link>
                        </div>
                    </li>
                    {renderMenu(menu)}
                </ul>

                <div className={styles.Avatar}>
                    <Avatar
                        src={avatar}
                        onClick={() =>
                          setIsUserDropdownVisible(!isUserDropdownVisible)
                        }
                    />
                    <div className={styles.UserDetails}>
                        <div className={styles.user_title}>
                            <div className={styles.UserName}>{userData.name}</div>
                            <div className={styles.UserPosition}>{userData.position}</div>
                        </div>
                        <div className={styles.arrow_down_icon}>
                            <IconArrowDown
                                size={'md'}
                                fill={'var(--black-80)'}
                                className={styles.UserDropdown}
                                onClick={() => setIsUserDropdownVisible(!isUserDropdownVisible)}
                            />
                        </div>
                    </div>
                    <ul
                        className={[
                          styles.UserMenu,
                          isUserDropdownVisible ? styles.show : styles.hide
                        ].join(' ')}
                    >
                        {renderUserMenu(userMenu)}
                    </ul>
                    <div
                        onClick={() => setIsUserDropdownVisible(false)}
                        className={[
                          styles.backdrop,
                          isUserDropdownVisible ? styles.show : styles.hide
                        ].join(' ')}
                    />
                </div>
                <div
                    className={styles.Collapse}
                    onClick={() => setIsMenuCollapsed(!isMenuCollapsed)}
                >
                    <div />
                </div>
            </section>

            <section
                className={[
                  styles.SubMenuWrapper,
                  subMenu ? styles.show : styles.hide
                ].join(' ')}
            >
                <nav className={styles.SubMenu}>{renderSubMenu()}</nav>
            </section>
        </nav>
  )
}

export default Sidebar
