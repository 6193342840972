import React from 'react'
import { connect } from 'react-redux'
import { DatePicker, ConfigProvider } from 'antd'
import { UpOutlined } from '@ant-design/icons'
import styles from './ByWeek.module.css'
import * as actionCreators from '../../../../../store/actions'
import 'moment/locale/en-gb'
import locale from 'antd/es/locale/en_GB'
import moment from 'moment'

moment.updateLocale('en-gb', {
  week: {
    dow: 1
  }
})

const filterKey = 'by_week'

const ByWeek = ({ date, storeFilter }) => {
  const datePickerChangeHandler = date => {
    const from = date ? date.clone().startOf('week') : null
    const to = date ? date.clone().endOf('week') : null

    storeFilter(filterKey, from, to)
  }

  return (
        <ConfigProvider locale={locale}>
            <DatePicker
                onChange={datePickerChangeHandler}
                picker="week"
                value={date}
                className={styles.DatePicker}
                placeholder="Select"
                suffixIcon={<UpOutlined />}
                dropdownClassName={styles.Dropdown}
                showToday={false}
            />
        </ConfigProvider>
  )
}

const mapStateToProps = state => {
  const from = state.financialAbuseRiskFilter.timeline.value.from
  const key = state.financialAbuseRiskFilter.timeline.key

  return {
    date: key === filterKey ? from : null
  }
}

const mapDispatchToProps = dispatch => {
  return {
    storeFilter: (key, from, to) => dispatch(actionCreators.filterTimelineRange(key, from, to))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ByWeek)
