import React from 'react'
import { Row, Col, Upload, Modal, Form } from 'antd'
import { Button, Avatar, IconCloseOutlined } from '@tutorbot/ui-components'
import { uploadImage, removeImage } from '../../services/api/ums/auth/user'
import { useSelector, useDispatch } from 'react-redux'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import { avatarImage } from '../../store/actions'
import styles from './Profile.module.css'

const FormItem = Form.Item

const avatarDefault = 'https://www.w3schools.com/howto/img_avatar.png'

const modal = (onOk) => {
  Modal.confirm({
    title: 'Confirm',
    icon: <IconCloseOutlined size={'sm'} fill={'var(--error-color)'} />,
    content: 'Are you sure you wish to permanently remove this photo?',
    okText: 'Delete',
    cancelText: 'Cancel',
    className: styles.modalContainer,
    onOk,
    okButtonProps: {
      style: {
        backgroundColor: 'var(--error-color)',
        borderColor: 'var(--error-color)'
      }
    }
  })
}

const UploadAvatarForm = () => {
  const [form] = Form.useForm()
  const dispatch = useDispatch()
  const avatar = useSelector((state) => state.avatarImage.avatar)

  const handleUpload = (file) => {
    const formData = new FormData()
    formData.append('files', file)

    uploadImage(formData)
      .then((result) => {
        dispatch(avatarImage(get(result, 'data[0].url')))
      })
      .catch((err) => console.log('err', err))
  }

  const handleRemove = () => {
    removeImage().then(() => dispatch(avatarImage()))
  }

  return (
        <Form
            form={form}
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 24 }}
            layout="vertical"
            validateTrigger="onChange"
        >
            <Row>
                <Col className={styles.avatarWrapper}>
                    <FormItem>
                        <Avatar src={avatar || avatarDefault} />
                    </FormItem>
                </Col>
                <FormItem
                    name="upload"
                    className={styles.uploadButtons}
                >
                    <Row>
                        <Upload
                            name="files"
                            listType="picture"
                            accept="image/*"
                            withCredentials
                            customRequest={({ file }) => handleUpload(file)}
                            itemRender={() => {}}
                        >
                            <Button className={styles.button}>
                                Upload a new photo
                            </Button>
                        </Upload>
                        <Button
                            className={styles.button}
                            onClick={() => modal(handleRemove)}
                            disabled={isEmpty(avatar)}
                        >
                            Remove photo
                        </Button>
                    </Row>
                </FormItem>
            </Row>
        </Form>
  )
}
export default UploadAvatarForm
