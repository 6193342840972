import React from 'react'
import { useSelector } from 'react-redux'
import Sidebar from '../../components/Sidebar/Sidebar'
import styles from './Layout.module.css'

const Layout = ({ children }) => {
  const { isAuthenticated: showSideBar } = useSelector(state => state.auth)

  return (
        <section className={styles.Layout}>
            {showSideBar ? <Sidebar /> : null}
            <main className={styles.Content}>
                {children}
            </main>
        </section>
  )
}

export default Layout
