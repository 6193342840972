import * as actionTypes from './actionTypes'

export const toggleStatusGraphicType = (isStatusAbsolute) => {
  return {
    type: actionTypes.TOGGLE_STATUS_GRAPHIC_TYPE,
    value: isStatusAbsolute
  }
}

export const handleInput = (val) => {
  return {
    type: actionTypes.HANDLE_INPUT,
    value: val
  }
}

export const handleStatisticView = (val) => {
  return {
    type: actionTypes.HANDLE_VIEW,
    value: val
  }
}
