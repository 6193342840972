import CallApi from '../../apiClient'
import { routes } from './routes'

export const login = ({ email, password }) => {
  return CallApi(routes.auth_login, { identifier: email, password })
}

export const getMe = () => {
  return CallApi(routes.auth_me)
}
