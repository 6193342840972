import React from 'react'
import { Divider } from 'antd'
import styles from './PageTitle.module.css'

const PageTitle = ({ title }) => {
  return (
        <div className={styles.container}>
            <h1 className={styles.title}>{title}</h1>
            <Divider className={styles.divider} />
        </div>
  )
}

export default PageTitle
