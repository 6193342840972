import * as actionTypes from '../actions/actionTypes'
import cloneDeep from 'lodash/cloneDeep'

const initialState = {
  term: ''
}

const handleInput = (state = initialState, action) => {
  const clonedState = cloneDeep(state)

  switch (action.type) {
    case actionTypes.HANDLE_INPUT:
      clonedState.term = action.value
      break
    default:
  }

  return clonedState
}

export default handleInput
