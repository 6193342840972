import React from 'react'
import styles from './Registration.module.css'
import Logo from '../../assets/icons/Logo'

const HeaderLogo = () => {
  return <div className={styles.header}>
        <Logo fill="var(--white-color)" height={70} width={67} />
    </div>
}

export default HeaderLogo
