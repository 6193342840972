import React, { useState, useEffect } from 'react'
import moment from 'moment'
import axios from 'axios'
import DataList from '../UI/DataList'
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs'
import styles from './Transaction.module.css'
import { useDispatch, useSelector } from 'react-redux'
import { handleSelectedEvent } from '../../store/actions'
import { listTransactions, countTransactions } from '../../services/api/vums'
import getSymbolFromCurrency from 'currency-symbol-map'

const TransactionList = () => {
  const defaultSortOrder = { field: 'date', order: 'descend' }

  const { date: month, key: vulnerabilityGuid } = useSelector(state => state.selectedVulnerability.vulnerability)
  const event = useSelector(state => state.selectedEvent.event)
  const dispatch = useDispatch()

  const renderCurrency = (value, { currency }) => {
    return <span className='default_color'>{getSymbolFromCurrency(currency)} {value}</span>
  }
  const columns = [
    {
      title: 'Date',
      dataIndex: 'date',
      align: 'center',
      width: 150,
      render: function renderDate (date) { return <span>{moment(date).format('DD-MM-YY')}</span> }
    },
    {
      title: 'Transaction',
      dataIndex: 'type',
      align: 'left',
      width: 300
    },
    {
      title: 'Merchant',
      dataIndex: 'merchant',
      align: 'right'
    },
    {
      title: 'Currency',
      dataIndex: 'currency',
      align: 'right'
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      align: 'right',
      render: renderCurrency
    }
  ]

  const createDataFilter = () => {
    const q = {}

    if (event.key) {
      q['vulnerability_event.guid'] = event.key
    }
    if (vulnerabilityGuid) {
      q['vulnerability_event.vulnerability_guid'] = vulnerabilityGuid
    }
    return q
  }
  const [dataFilter, setDataFilter] = useState(createDataFilter())
  useEffect(() => {
    setDataFilter(createDataFilter())
  }, [event, vulnerabilityGuid])

  const dataUpdater = (filterString, dataSetter, countSetter) => {
    return axios.all([listTransactions(filterString), countTransactions(filterString)])
      .then(axios.spread(({ data: transactions }, { data: count }) => {
        countSetter(count)

        dataSetter(
          transactions.map(item => ({
            key: item.guid,
            date: item.date,
            type: item.type,
            merchant: item.merchant && item.merchant.code,
            amount: item.amount,
            currency: item.currency || ''
          }))
        )
      }))
  }

  const renderEventName = () => {
    return Object.keys(event).length
      ? moment(event.date).format('DD-MM-YY') + ' ' + event.event_name
      : null
  }

  return (
        <>
            <div className={styles.horizontal_divider} />
            <Breadcrumbs
                month={month && moment(month).format('MMMM')}
                monthName={renderEventName()}
                handleOnClick={() => dispatch(handleSelectedEvent({}))}
            />
            <DataList {...{ columns, defaultSortOrder, dataUpdater, dataFilter }}/>
        </>
  )
}

export default TransactionList
