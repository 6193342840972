import React from 'react'
import { Col } from 'antd'
import { CardContainer, IconDropBox } from '@tutorbot/ui-components'
import TransactionList from '../TransactionList/TransactionList'
import { useSelector } from 'react-redux'
import styles from './Breakdown.module.css'

const Breakdown = () => {
  const { key: vulnerabilityGuid } = useSelector(state => state.selectedVulnerability.vulnerability)
  const { key: eventGuid } = useSelector(state => state.selectedEvent.event)

  return (
        <Col
            lg={{ span: 12 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
            xs={{ span: 24 }}
            className={[styles.breakdown_container, 'gutter-row']}
        >
            <CardContainer>
                <h2>Breakdown</h2>
                {vulnerabilityGuid || eventGuid
                  ? <TransactionList />
                  : <Col className={styles.breakdown_content}>
                        <IconDropBox fill={'var(--black-100)'} />
                        <h1>Selects an<br />month / event</h1>
                    </Col>
                }
            </CardContainer>
        </Col>
  )
}

export default Breakdown
