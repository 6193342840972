import React from 'react'
import Above from '../filters/NumberOfTransactions/Above'
import Below from '../filters/NumberOfTransactions/Below'
import SpecificRange from '../filters/NumberOfTransactions/SpecificRange'

export const NumberOfTransactionsFilter = [
  {
    value: 'above',
    name: 'Above',
    filter: <Above />
  }, {
    value: 'below',
    name: 'Below',
    filter: <Below />
  }, {
    value: 'specific_range',
    name: 'Specific range',
    filter: <SpecificRange />
  }
]
