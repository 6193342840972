import CallApi from '../../apiClient'
import { routes } from './routes'

export const register = async (data) => {
  return CallApi(routes.auth_register, data)
}

export const resendVerification = async ({ email }) => {
  return CallApi(routes.auth_send_email_confirmation, { email })
}

export const handleAuthenticationData = async ({ jwt, user }) => {
  // @todo handle jwt token and user object
  // add user to redux as authenticatedUser
  // store jwt in cookies or in more secure place
  console.log({ jwt, user })
}
