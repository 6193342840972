import cloneDeep from 'lodash/cloneDeep'
import * as actionTypes from '../actions/actionTypes'

const initialState = {
  isStatusAbsolute: true
}

const reducer = (state = initialState, action) => {
  const clonedState = cloneDeep(state)

  switch (action.type) {
    case actionTypes.TOGGLE_STATUS_GRAPHIC_TYPE:
      clonedState.isStatusAbsolute = action.value
      break

    default:
  }

  return clonedState
}

export default reducer
