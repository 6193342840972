import moment from 'moment'

const vulnerabilityOrder = [
  'vulnerable',
  'potentially_vulnerable',
  'not_vulnerable'
]

const vulnerabilityColorMap = {
  vulnerable: '#fb4e4e',
  potentially_vulnerable: '#f6a609',
  not_vulnerable: '#2ac769'
}

const vulnerabilityPriority = {}
const vulnerabilityStrings = {}

const capitalize = (s) => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}

vulnerabilityOrder.forEach((v, i) => {
  vulnerabilityPriority[v] = i + 1
  vulnerabilityStrings[v] = v.split('_').map(p => capitalize(p)).join(' ')
})

const getLastEventStatus = (vulnerabilities) => {
  let lastDate, lastStatus
  vulnerabilities.forEach(v => {
    if (v.target === 'customer') { return }
    const date = moment(v.date)
    if (lastDate === undefined || date > lastDate) {
      lastDate = date.clone()
      lastStatus = v.event_status
    }
  })
  return lastStatus
}

const getHighestEventStatus = (vulnerabilities) => {
  let status = vulnerabilityOrder[vulnerabilityOrder.length - 1]
  vulnerabilities.forEach(v => {
    if (vulnerabilityPriority[v.event_status] < vulnerabilityPriority[status]) {
      status = v.event_status
    }
  })
  return status
}

export { vulnerabilityOrder, vulnerabilityPriority, vulnerabilityStrings, vulnerabilityColorMap, getHighestEventStatus, getLastEventStatus }
