import React from 'react'
import { Radio } from 'antd'
import { IconBarChartOutlined, IconUnorderedListOutlined } from '@tutorbot/ui-components'
import styles from '../ToggleButton/ToggleButton.module.css'

const ToggleButton = ({ view, currentView }) => {
  return (
        <>
            <Radio.Button
                className={styles.diagram_button}
                value="graphic"
                checked={currentView === 'graphic'}
                type='default'
                onClick={() => view('graphic')}
            >
                <IconBarChartOutlined fill={''} />
            </Radio.Button>
            <Radio.Button
                className={styles.order_button}
                value="order-list"
                checked={currentView === 'order-list'}
                type='default'
                onClick={() => view('order-list')}
            >
                <IconUnorderedListOutlined fill={''} />
            </Radio.Button>
        </>
  )
}

export default ToggleButton
