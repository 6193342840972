import * as actionTypes from '../actions/actionTypes'
import cloneDeep from 'lodash/cloneDeep'

const initialState = {
  view: 'graphic'
}

const handleStatisticView = (state = initialState, action) => {
  const clonedState = cloneDeep(state)

  switch (action.type) {
    case actionTypes.HANDLE_VIEW:
      clonedState.view = action.value
      break
    default:
  }

  return clonedState
}

export default handleStatisticView
