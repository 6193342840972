import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setSelectedVulnerabilityStatuses } from '../../store/actions/financialAbuseRiskFilter'
import { Wrapper } from '@tutorbot/ui-components'
import { Checkbox } from 'antd'
import { vulnerabilityStrings, vulnerabilityOrder } from '../../services/vulnerability'
import style from './ButtonGroup.module.css'

const ButtonGroup = () => {
  const selectedStatuses = useSelector(state => state.financialAbuseRiskFilter.selectedVulnerabilityStatuses)
  const dispatch = useDispatch()
  const btnList = Object.keys(vulnerabilityStrings).map(item => {
    return {
      label: vulnerabilityStrings[item],
      value: item
    }
  })

  const [isAllCustomersSelected, setIsAllCustomersSelected] = useState(btnList.length === selectedStatuses.length)

  const checkboxGroupChangeHandler = (e) => {
    if (e.length === 0) {
      e = vulnerabilityOrder
    }
    setIsAllCustomersSelected(e.length === btnList.length)
    dispatch(setSelectedVulnerabilityStatuses(e))
  }

  const selectAllHandler = (e) => {
    dispatch(setSelectedVulnerabilityStatuses(vulnerabilityOrder))
    setIsAllCustomersSelected(true)
  }

  return (
        <Wrapper pl='1' pr='1' pt='2' pb='2'>
            <div className={style.button_group}>
                <Checkbox
                    className={[style.Checkbox, isAllCustomersSelected ? style.active : ''].join(' ')}
                    onChange={selectAllHandler}
                    checked={isAllCustomersSelected}
                >
                    All Customers
                </Checkbox>
                <Checkbox.Group
                    options={btnList}
                    value={isAllCustomersSelected ? [] : selectedStatuses}
                    onChange={checkboxGroupChangeHandler}
                    className={isAllCustomersSelected ? style.isAllSelected : ''}
                />
            </div>
        </Wrapper>
  )
}

export default ButtonGroup
