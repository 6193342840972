import * as actionTypes from '../actions/actionTypes'
import cloneDeep from 'lodash/cloneDeep'

const avatarImage = (state = {}, action) => {
  const clonedState = cloneDeep(state)

  switch (action.type) {
    case actionTypes.AVATAR_IMAGE_SET:
      return (clonedState.avatarImage = {
        avatar: action.value
      })
    default:
      return state
  }
}

export default avatarImage
