import React, { useCallback, useEffect, useState } from 'react'
import ReactApexChart from 'react-apexcharts'
import { useSelector } from 'react-redux'
import { buildVulnerabilityFilters } from '../../../services/filterBuilder'
import { listVulnerabilitiesStats } from '../../../services/api'
import { vulnerabilityColorMap, vulnerabilityOrder } from '../../../services/vulnerability'
import NoDataAvailable from '../../UI/NoDataAvailable/NoDataAvailable'
import ContentLoader from '../../../helpers/Loader/Loader'
import isEmpty from 'lodash/isEmpty'
import qs from 'qs'
import moment from 'moment-timezone'
import styles from './ApexChart.module.css'

const yLabelScales = [
  { gte: 0, lt: 10, label: '-', mlp: 1 },
  { gte: 10, lt: 50, label: '-', mlp: 1 },
  { gte: 50, lt: 100, label: '0', mlp: 10 },
  { gte: 100, lt: 500, label: '0', mlp: 10 },
  { gte: 500, lt: 1000, label: '00', mlp: 100 },
  { gte: 1000, lt: 5000, label: '00', mlp: 100 },
  { gte: 5000, lt: 10000, label: '000', mlp: 1000 },
  { gte: 10000, lt: 50000, label: '000', mlp: 1000 },
  { gte: 50000, lt: 100000, label: '0,000', mlp: 10000 },
  { gte: 100000, lt: 500000, label: '0,000', mlp: 10000 },
  { gte: 500000, lt: 1000000, label: '00,000', mlp: 100000 },
  { gte: 1000000, lt: 5000000, label: '00,000', mlp: 100000 },
  { gte: 5000000, lt: 10000000, label: '000,000', mlp: 1000000 },
  { gte: 10000000, lt: 50000000, label: '000,000', mlp: 1000000 }
]

const selectedStatuses = vulnerabilityOrder

const ApexChart = ({ setDateEnd }) => {
/*
  [{
    date: moment(),
    total_customers: number
    event_status: string
  }]
*/
  const [data, setData] = useState([])
  const [totalCustomers, setTotalCustomers] = useState(null)
  const [labelConfig, setLabelConfig] = useState(yLabelScales[0])
  const [graphDateFormat, setGraphDateFormat] = useState('DD MMM YYYY')
  const [isLoading, setIsLoading] = useState(false)
  const formatDate = 'DD MMM Y'

  const isStatusAbsolute = useSelector(state => state.statusGraphic.isStatusAbsolute)

  const selectedLabels = useSelector(
    (state) => state.financialAbuseRiskFilter.selectedVulnerabilityStatuses
  )

  useEffect(() => {
    setGraphConfig({
      ...defaultGraphConfig,
      series: data
    })
  }, [isStatusAbsolute, data, totalCustomers])

  // filter +
  const timeline = useSelector(
    (state) => state.financialAbuseRiskFilter.timeline
  )
  const numberOfTransactions = useSelector(
    (state) => state.financialAbuseRiskFilter.numberOfTransactions
  )
  const numberOfAccounts = useSelector(
    (state) => state.financialAbuseRiskFilter.numberOfAccounts
  )

  const getFilters = useCallback(() => {
    return buildVulnerabilityFilters({
      timeline,
      numberOfTransactions,
      numberOfAccounts
    })
  }, [
    timeline.value.to,
    timeline.value.from,
    numberOfTransactions.value.to,
    numberOfTransactions.value.from,
    numberOfAccounts.value.to,
    numberOfAccounts.value.from,
    isStatusAbsolute
  ])

  const getCustomers = useCallback(() => {
    setIsLoading(true)
    const filters = getFilters()
    listVulnerabilitiesStats(
      qs.stringify(
        Object.assign(
          {
            target: 'monthly_breakdown',
            selected_labels: selectedLabels,
            isStatusAbsolute: Number(isStatusAbsolute)
          },
          filters
        )
      )
    )
      .then(({ data }) => {
        setIsLoading(false)
        const { dataPoints, config } = data
        setLabelConfig(yLabelScales.find(yScale => {
          if (isEmpty(config)) {
            return {}
          }
          return config.maxY >= yScale.gte && config.maxY < yScale.lt
        }))
        setGraphDateFormat(config.graphDateFormat || graphDateFormat)

        setData(dataPoints)
        setTotalCustomers(config.total_customers)
        setDateEnd(moment(config.dateEnd).tz('UTC').format(formatDate))
      })
      .catch((error) => {
        console.log('Fetch data failed', error)
      })
  }, [getFilters, selectedLabels.join(' ')])

  useEffect(() => {
    getCustomers()
  }, [getCustomers])

  const defaultGraphConfig = {
    series: [],
    options: {
      chart: {
        width: '100%',
        type: 'line',
        animations: {
          enabled: false
        },
        zoom: {
          enabled: false
        },
        toolbar: {
          show: false
        }
      },
      yaxis: {
        tickAmount: 10,
        min: 0,
        max: isStatusAbsolute ? labelConfig.lt : 100,
        floating: false,
        labels: {
          formatter: function (val) {
            return isStatusAbsolute ? val / labelConfig.mlp : val
          }
        }
      },
      xaxis: {
        type: 'numeric',
        labels: {
          show: true,
          rotate: 0,
          rotateAlways: false,
          hideOverlappingLabels: true,
          showDuplicates: false,
          trim: false,
          style: {
            fontSize: '12px',
            fontFamily: 'Montserrat',
            fontWeight: 500,
            cssClass: 'apexcharts-xaxis-label'
          },
          formatter: function (val) {
            const formatted = moment(val).format(graphDateFormat)
            return formatted.split('\n')
          }
        },
        axisTicks: {
          show: false
        }
      },
      noData: {
        text: 'No Data result',
        align: 'center',
        verticalAlign: 'middle',
        offsetX: 0,
        offsetY: 0,
        style: {
          color: 'var(--black-60)',
          fontSize: '15px',
          fontFamily: 'Montserrat'
        }
      },
      x: {
        show: false
      },
      tooltip: {
        followCursor: true,
        shared: true,
        y: {
          formatter: (val) => {
            return val
          }
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        width: [2, 2, 2],
        curve: 'straight'
      },
      markers: {
        size: 0,
        hover: {
          sizeOffset: 0
        }
      },
      grid: {
        borderColor: 'var(--black-10)'
      },
      legend: {
        show: false
      },
      colors: selectedLabels.map((status) => vulnerabilityColorMap[status])
    }
  }
  const [graphConfig, setGraphConfig] = useState(defaultGraphConfig)

  return (
    <>
      <div className={styles.y_axis}>
        <div className={styles.y_axis_text}>
          {isStatusAbsolute
            ? `Number of customers (${labelConfig.label})`
            : 'Percentage of customers'}
        </div>
      </div>

      <div className={styles.graph_list}>
        {isLoading || data.length
          ? (
            <div className={styles.graphic}>
              {
                isLoading
                  ? <div className={styles.content_loader}>
                      <ContentLoader
                        type='TailSpin'
                        color='var(--primary-color)'
                        height={50}
                        width={50}
                      />
                    </div>
                  : <ReactApexChart
                    options={graphConfig.options}
                    series={graphConfig.series}
                    type='line'
                    height={450}
                  />
              }
            </div>
            )
          : <NoDataAvailable />
        }
      </div>

      {/* Custom legend */}
      <div className={styles.legend_container}>
        {selectedStatuses.includes('vulnerable') && (
          <div className={styles.legend}>
            <div className={styles.vulnerable} />
            Vulnerable
          </div>
        )}
        {selectedStatuses.includes('potentially_vulnerable') && (
          <div className={styles.legend}>
            <div className={styles.potentially_vulnerable} />
            Potentially vulnerable
          </div>
        )}
        {selectedStatuses.includes('not_vulnerable') && (
          <div className={styles.legend}>
            <div className={styles.not_vulnerable} />
            Not vulnerable
          </div>
        )}
      </div>
    </>
  )
}

export default ApexChart
