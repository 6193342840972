const UMS_API_HOST = window.REACT_APP_KALGERA_UMS_API_HOST
const getFullUrl = (path) => UMS_API_HOST + path

export const routes = {
  auth_me: { method: 'GET', url: getFullUrl('/users/me') },
  auth_login: { method: 'POST', url: getFullUrl('/auth/local') },
  auth_register: { method: 'POST', url: getFullUrl('/auth/local/register') },
  auth_forgot_password: {
    method: 'POST',
    url: getFullUrl('/auth/forgot-password')
  },
  auth_reset_password: {
    method: 'POST',
    url: getFullUrl('/auth/reset-password')
  },
  auth_send_email_confirmation: {
    method: 'POST',
    url: getFullUrl('/auth/send-email-confirmation')
  },
  user_profile_update: {
    method: 'PUT',
    url: getFullUrl('/users/profile')
  },
  user_change_password: {
    method: 'PUT',
    url: getFullUrl('/users/password')
  },
  user_upload_image: {
    method: 'POST',
    url: getFullUrl('/users/me/profile/image')
  },
  user_get_image: {
    method: 'GET',
    url: getFullUrl('/users/me/profile/image')
  },
  user_remove_image: {
    method: 'DELETE',
    url: getFullUrl('/users/me/profile/image')
  }
}
