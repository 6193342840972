import * as actionTypes from './actionTypes'

const makeSecure = window.REACT_APP_ENV === 'production'

export const setAuthenticatedUser = (user) => {
  return {
    type: actionTypes.AUTH_USER,
    value: user
  }
}

export const setAuthenticationToken = (token) => {
  // used in lower environments to not force user to login after each page refresh
  makeSecure || localStorage.setItem('token', token)
  return {
    type: actionTypes.AUTH_TOKEN,
    value: token
  }
}

export const showUnauthorizedError = (showError) => {
  return {
    type: actionTypes.ERROR_UNAUTHORIZED,
    value: showError
  }
}
