import React from 'react'
import Header from '../../components/Header/Header'
import DataUploadHistory from '../../components/DataUploadHistory/DataUploadHistory'

const DataManagement = () => {
  return (
        <>
            <Header title="Data Management" showUploadButton/>
            <DataUploadHistory />
        </>
  )
}

export default DataManagement
