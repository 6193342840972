import React from 'react'
import { connect } from 'react-redux'
import { InputNumber } from 'antd'
import styles from './SpecificRange.module.css'
import * as actionCreators from '../../../../../store/actions'

const filterKey = 'specific_range'

const Above = ({ numberOfAccountFrom, numberOfAccountTo, storeFilter }) => {
  const inputNumberFromChangeHandler = numberOfAccount => {
    const to = numberOfAccountTo < numberOfAccount ? numberOfAccount : numberOfAccountTo
    storeFilter(filterKey, numberOfAccount, to)
  }

  const inputNumberToChangeHandler = numberOfAccount => {
    let from = numberOfAccountFrom || 0

    from = from > numberOfAccount ? numberOfAccount : from
    storeFilter(filterKey, from, numberOfAccount)
  }

  return (
        <div className={styles.InputNumberWrapper}>
            <InputNumber
                min={1}
                value={numberOfAccountFrom}
                onChange={inputNumberFromChangeHandler}
                className={styles.InputNumber}
                placeholder="From"
            />
            <InputNumber
                min={1}
                value={numberOfAccountTo}
                onChange={inputNumberToChangeHandler}
                className={styles.InputNumber}
                placeholder="To"
            />
        </div>
  )
}

const mapStateToProps = state => {
  const from = state.financialAbuseRiskFilter.numberOfAccounts.value.from
  const to = state.financialAbuseRiskFilter.numberOfAccounts.value.to
  const key = state.financialAbuseRiskFilter.numberOfAccounts.key

  return {
    numberOfAccountFrom: key === filterKey ? from : null,
    numberOfAccountTo: key === filterKey ? to : null
  }
}

const mapDispatchToProps = dispatch => {
  return {
    storeFilter: (key, from, to) => dispatch(actionCreators.filterNumberOfAccountsRange(key, from, to))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Above)
