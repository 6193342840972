import CallApi from '../../apiClient'
import { routes } from './routes'

export const updateUserProfile = async (data) => {
  return CallApi(routes.user_profile_update, data)
}

export const changeUserPassword = async (data) => {
  return CallApi(routes.user_change_password, data)
}

export const uploadImage = async (data) => {
  return CallApi(routes.user_upload_image, data, {
    'Content-Type': 'multipart/form-data'
  })
}
export const getImage = async () => {
  return CallApi(routes.user_get_image)
}
export const removeImage = async () => {
  return CallApi(routes.user_remove_image)
}
