import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Radio, Tag } from 'antd'
import { IconPlusOutlined } from '@tutorbot/ui-components'
import { TimelineFilter } from './data/TimelineFilter'
import { NumberOfTransactionsFilter } from './data/NumberOfTransactionsFilter'
import { NumberOfAccountsFilter } from './data/NumberOfAccountsFilter'

import styles from './FinancialAbuseRiskFilter.module.css'
import * as actionCreators from '../../../store/actions'

const FinancialAbuseRiskFilter = ({
  timeline,
  numberOfTransactions,
  numberOfAccounts,
  resetTimelineFilter,
  resetNumberOfTransactionsFilter,
  resetNumberOfAccountsFilter,
  storeTimelineFilter
}) => {
  const [isDropdownVisible, setIsDropdownVisible] = useState(false)
  const [isTimelineSubmenuVisible, setTimelineSubmenuVisible] = useState(false)
  const [isNumberOfTransactionsSubmenuVisible, setIsNumberOfTransactionsSubmenuVisible] = useState(false)
  const [isNumberOfAccountsSubmenuVisible, setIsNumberOfAccountsSubmenuVisible] = useState(false)

  const [timelineFilterValue, setTimelineFilterValue] = React.useState(null)
  const [numberOfTransactionsFilterValue, setNumberOfTransactionsFilterValue] = React.useState(null)
  const [numberOfAccountsFilterValue, setNumberOfAccountsFilterValue] = React.useState(null)

  useEffect(() => {
    if (timeline.value.from === null && timeline.value.to === null) {
      setTimelineFilterValue(false)
    }

    if (numberOfTransactions.value.from === null && numberOfTransactions.value.to === null) {
      setNumberOfTransactionsFilterValue(false)
    }

    if (numberOfAccounts.value.from === null && numberOfAccounts.value.to === null) {
      setNumberOfAccountsFilterValue(false)
    }
  }, [timeline, numberOfTransactions, numberOfAccounts])

  const toggleDropdown = () => {
    setIsDropdownVisible(!isDropdownVisible)
    setTimelineSubmenuVisible(false)
    setIsNumberOfTransactionsSubmenuVisible(false)
    setIsNumberOfAccountsSubmenuVisible(false)
  }

  const showSubMenu = (key) => {
    switch (key) {
      case 'timeline':
        setTimelineSubmenuVisible(!isTimelineSubmenuVisible)
        setIsNumberOfTransactionsSubmenuVisible(false)
        setIsNumberOfAccountsSubmenuVisible(false)
        break

      case 'numberOfTransactions':
        setTimelineSubmenuVisible(false)
        setIsNumberOfTransactionsSubmenuVisible(!isNumberOfTransactionsSubmenuVisible)
        setIsNumberOfAccountsSubmenuVisible(false)
        break

      case 'numberOfAccounts':
        setTimelineSubmenuVisible(false)
        setIsNumberOfTransactionsSubmenuVisible(false)
        setIsNumberOfAccountsSubmenuVisible(!isNumberOfAccountsSubmenuVisible)
        break

      default:
    }
  }

  const renderFilter = (filter, filterValue) => {
    return filter.map(item => (
            <li key={item.value}>
                <Radio
                    value={item.value}
                    onChange={e => {
                      if (typeof item.onSelect === 'function') {
                        item.onSelect(e, storeTimelineFilter)
                      }
                    }}
                >
                    {item.name}
                </Radio>
                <div className={filterValue === item.value ? styles.show : styles.hide}>{item.filter}</div>
            </li>
    ))
  }

  const renderTags = () => {
    let timelineTag = null
    let numberOfTransactionsTag = null
    let numberOfAccountsTag = null

    switch (timeline.key) {
      case 'year_to_date':
        timelineTag = timeline.value.from ? 'Year to date' : null
        break

      case 'specific_range':
        timelineTag = timeline.value.from || timeline.value.to
          ? `
                        From ${timeline.value.from.format('YYYY-MM-DD')} 
                        to ${timeline.value.to.format('YYYY-MM-DD')}
                    `
          : null
        break

      case 'by_day':
        timelineTag = timeline.value.from ? timeline.value.from.format('YYYY-MM-DD') : null
        break

      case 'by_week':
        timelineTag = timeline.value.from
          ? `By ${timeline.value.from.week()}th week ${timeline.value.from.format('YYYY')} `
          : null
        break

      case 'by_month':
        timelineTag = timeline.value.from
          ? `${timeline.value.from.format('MMMM YYYY')}`
          : null
        break

      case 'by_year':
        timelineTag = timeline.value.from ? `By year ${timeline.value.from.year()}` : null
        break

      default:
    }

    switch (numberOfTransactions.key) {
      case 'above':
        numberOfTransactionsTag = numberOfTransactions.value.from
          ? `Number of transactions above ${numberOfTransactions.value.from}`
          : null
        break

      case 'below':
        numberOfTransactionsTag = numberOfTransactions.value.to
          ? `Number of transactions below ${numberOfTransactions.value.to}`
          : null
        break

      case 'specific_range':
        numberOfTransactionsTag = numberOfTransactions.value.from || numberOfTransactions.value.to
          ? `
                        Number of transactions 
                        from ${numberOfTransactions.value.from !== null ? numberOfTransactions.value.from : '_'} 
                        to ${numberOfTransactions.value.to !== null ? numberOfTransactions.value.to : '_'}
                    `
          : null
        break

      default:
    }

    switch (numberOfAccounts.key) {
      case 'above':
        numberOfAccountsTag = numberOfAccounts.value.from
          ? `Number of accounts above ${numberOfAccounts.value.from}`
          : null
        break

      case 'below':
        numberOfAccountsTag = numberOfAccounts.value.to
          ? `Number of accounts below ${numberOfAccounts.value.to}`
          : null
        break

      case 'specific_range':
        numberOfAccountsTag = numberOfAccounts.value.from || numberOfAccounts.value.to
          ? `
                        Number of accounts 
                        from ${numberOfAccounts.value.from !== null ? numberOfAccounts.value.from : '_'} 
                        to ${numberOfAccounts.value.to !== null ? numberOfAccounts.value.to : '_'}
                    `
          : null
        break

      default:
    }

    return (
            <>
                {timelineTag && (
                    <Tag closable className={styles.DropdownTag} onClose={resetTimelineFilter}>
                        {timelineTag}
                    </Tag>
                )}
                {numberOfTransactionsTag && (
                    <Tag closable className={styles.DropdownTag} onClose={resetNumberOfTransactionsFilter}>
                        {numberOfTransactionsTag}
                    </Tag>
                )}
                {numberOfAccountsTag && (
                    <Tag closable className={styles.DropdownTag} onClose={resetNumberOfAccountsFilter}>
                        {numberOfAccountsTag}
                    </Tag>
                )}
            </>
    )
  }

  return (
        <div className={styles.DropdownMenu}>

            {renderTags()}

            <div className={styles.DropdownLabel} onClick={toggleDropdown}>
                Filter <IconPlusOutlined size={'sm'} fill={'var(--primary-color)'} />
            </div>
            <ul className={isDropdownVisible ? styles.show : styles.hide}>

                <li>
                    <a href='/#' onClick={() => showSubMenu('timeline')}>Timeline</a>
                    <Radio.Group
                        onChange={e => setTimelineFilterValue(e.target.value)}
                        value={timelineFilterValue}
                    >
                        <ul className={isTimelineSubmenuVisible ? styles.show : styles.hide}>
                            {renderFilter(TimelineFilter, timelineFilterValue)}
                        </ul>
                    </Radio.Group>
                </li>

                <li>
                    <a href='/#' onClick={() => showSubMenu('numberOfTransactions')} >
                        Number of transactions
                    </a>
                    <Radio.Group
                        onChange={e => setNumberOfTransactionsFilterValue(e.target.value)}
                        value={numberOfTransactionsFilterValue}
                    >
                        <ul className={isNumberOfTransactionsSubmenuVisible ? styles.show : styles.hide}>
                            {renderFilter(NumberOfTransactionsFilter, numberOfTransactionsFilterValue)}
                        </ul>
                    </Radio.Group>
                </li>

                <li>
                    <a href='/#' onClick={() => showSubMenu('numberOfAccounts')} >
                        Number of accounts
                    </a>
                    <Radio.Group
                        onChange={e => setNumberOfAccountsFilterValue(e.target.value)}
                        value={numberOfAccountsFilterValue}
                    >
                        <ul className={isNumberOfAccountsSubmenuVisible ? styles.show : styles.hide}>
                            {renderFilter(NumberOfAccountsFilter, numberOfAccountsFilterValue)}
                        </ul>
                    </Radio.Group>
                </li>

            </ul>
            <div
                onClick={() => setIsDropdownVisible(false)}
                className={[styles.backdrop, isDropdownVisible ? styles.show : styles.hide].join(' ')}
            />
        </div>
  )
}

const mapStateToProps = state => {
  return {
    timeline: state.financialAbuseRiskFilter.timeline,
    numberOfTransactions: state.financialAbuseRiskFilter.numberOfTransactions,
    numberOfAccounts: state.financialAbuseRiskFilter.numberOfAccounts
  }
}

const mapDispatchToProps = dispatch => {
  return {
    storeTimelineFilter: (key, from, to) => dispatch(actionCreators.filterTimelineRange(key, from, to)),
    resetTimelineFilter: () => dispatch(actionCreators.filterTimelineReset()),
    resetNumberOfTransactionsFilter: () => dispatch(actionCreators.filterNumberOfTransactionsReset()),
    resetNumberOfAccountsFilter: () => dispatch(actionCreators.filterNumberOfAccountsReset())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FinancialAbuseRiskFilter)
