import CallApi from '../../apiClient'
import { routes } from './routes'

const requestPasswordReset = async (email) => {
  return CallApi(routes.auth_forgot_password, { email })
}

const resetPassword = async ({ code, password, passwordConfirmation }) => {
  return CallApi(routes.auth_reset_password, { code, password, passwordConfirmation })
}

export { requestPasswordReset, resetPassword }
