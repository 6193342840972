import CallApi from '../apiClient'
import qs from 'qs'

const DMS_API_HOST = window.REACT_APP_KALGERA_DMS_API_HOST

const getFullUrl = path => DMS_API_HOST + path

const routes = {
  file_list: { method: 'GET', url: getFullUrl('/files') },
  file_count: { method: 'GET', url: getFullUrl('/files/count') },
  file_create: { method: 'POST', url: getFullUrl('/files/chunks-completion') },
  file_create_chunk: { method: 'POST', url: getFullUrl('/files/chunks') },
  file_delete: { method: 'DELETE', url: getFullUrl('/files/:guid') }
}

const listFiles = async (queryString) => {
  return CallApi({ ...routes.file_list, params: qs.parse(queryString) })
}

const countFiles = async (queryString) => {
  return CallApi({ ...routes.file_count, params: qs.parse(queryString) })
}

const createFileChunk = async (data) => {
  const postData = new FormData()
  Object.keys(data).forEach(key => {
    postData.append(key, data[key])
  })

  return CallApi(routes.file_create_chunk, postData, { 'Content-Type': 'multipart/form-data' })
}

const createFile = async (data) => {
  return CallApi(routes.file_create, data)
}
const deleteFile = async (guid) => {
  const { url, ...rest } = routes.file_delete
  return CallApi({ ...rest, url: url.replace(':guid', guid) })
}

export { listFiles, countFiles, createFile, createFileChunk, deleteFile }
