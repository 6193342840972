import React from 'react'
import { connect } from 'react-redux'
import { InputNumber } from 'antd'
import styles from './SpecificRange.module.css'
import * as actionCreators from '../../../../../store/actions'

const filterKey = 'specific_range'

const Above = ({ numberOfTransactionFrom, numberOfTransactionTo, storeFilter }) => {
  const inputNumberFromChangeHandler = numberOfTransaction => {
    const to = numberOfTransactionTo < numberOfTransaction ? numberOfTransaction : numberOfTransactionTo
    storeFilter(filterKey, numberOfTransaction, to)
  }

  const inputNumberToChangeHandler = numberOfTransaction => {
    let from = numberOfTransactionFrom || 0

    from = from > numberOfTransaction ? numberOfTransaction : from
    storeFilter(filterKey, from, numberOfTransaction)
  }

  return (
        <div className={styles.InputNumberWrapper}>
            <InputNumber
                min={1}
                value={numberOfTransactionFrom}
                onChange={inputNumberFromChangeHandler}
                className={styles.InputNumber}
                placeholder="From"
            />
            <InputNumber
                min={1}
                value={numberOfTransactionTo}
                onChange={inputNumberToChangeHandler}
                className={styles.InputNumber}
                placeholder="To"
            />
        </div>
  )
}

const mapStateToProps = state => {
  const from = state.financialAbuseRiskFilter.numberOfTransactions.value.from
  const to = state.financialAbuseRiskFilter.numberOfTransactions.value.to
  const key = state.financialAbuseRiskFilter.numberOfTransactions.key

  return {
    numberOfTransactionFrom: key === filterKey ? from : null,
    numberOfTransactionTo: key === filterKey ? to : null
  }
}

const mapDispatchToProps = dispatch => {
  return {
    storeFilter: (key, from, to) => dispatch(actionCreators.filterNumberOfTransactionsRange(key, from, to))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Above)
