import React, { useEffect, useRef, useState } from 'react'
import IdleTimer from 'react-idle-timer'
import moment from 'moment'
import { useHistory } from 'react-router-dom'
import { getMe, updateUserProfile } from '../../services/api'

const SEND_ACTION_INTERVAL = 1 // minute
const INACTIVE_DURATION = 5 // minute

const IdleTimerContainer = ({ children }) => {
  const [lastActionAt, setLastActionAt] = useState(moment())
  const [actionSendAt, setActionSendAt] = useState(moment())

  const history = useHistory()
  const idleTimerRef = useRef(null)
  const inactiveTime = 1000 * 60 * INACTIVE_DURATION // 5min.

  const skipCheck = () => {
    return window.location.pathname === '/registration'
  }

  useEffect(async () => {
    // send updates to backend
    if (lastActionAt.diff(actionSendAt, 'minutes') >= SEND_ACTION_INTERVAL) {
      await updateUserProfile({})
      setActionSendAt(moment())
    }
  }, [lastActionAt])

  const onAction = () => {
    if (skipCheck()) {
      return
    }
    setLastActionAt(moment())
  }
  const onIdle = async () => {
    if (skipCheck()) {
      return
    }
    try {
      const { me } = await getMe()
      if (moment().diff(moment(me.last_active_at), 'minutes') < INACTIVE_DURATION) {
        return
      }
    } catch (e) {
      console.warn(e)
    }
    history.push('/registration?action=login')
  }

  return (
    <IdleTimer
      ref={idleTimerRef}
      timeout={inactiveTime}
      onAction={onAction}
      onIdle={onIdle}
      debounce={500}
    >
      {children}
    </IdleTimer>
  )
}

export default IdleTimerContainer
