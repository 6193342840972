import React from 'react'
import { Form } from 'antd'
import styles from '../Registration.module.css'
import Input from '../../../components/UI/Input/Input'
import { Button, Text } from '@tutorbot/ui-components'
import { requestPasswordReset } from '../../../services/api'
import { emailAddress } from '../FormInputs'

/**
 *
 * @param next callback to navigate to the next step
 * @returns {JSX.Element}
 * @constructor
 */
const ForgotPassword = ({ setIdentity }) => {
  const [form] = Form.useForm()

  const sendForgotPassword = ({ email }) => {
    requestPasswordReset(email).finally(() => {
      setIdentity(email)
    })
  }

  return (
        <div className={styles.forgotPasswordContainer}>
            <Text level={1} semiBold>
                Forgot Password
            </Text>
            <p className={styles.text}>
                Please enter your email address and we’ll send you a link to
                reset your password.
            </p>
            <Form
                form={form}
                layout="vertical"
                initialValues={{}}
                className="form"
                validateTrigger="onBlur"
                onFinish={sendForgotPassword}
            >
                <Form.Item
                    name={emailAddress.fieldName}
                    label={emailAddress.name}
                    rules={emailAddress.rules}
                >
                    <Input
                        placeholder={emailAddress.placeholder}
                        type={emailAddress.type}
                        prefix={emailAddress.icon}
                    />
                </Form.Item>
                <Form.Item>
                    <Button className={styles.button}>Send reset link</Button>
                </Form.Item>
            </Form>
        </div>
  )
}

export default ForgotPassword
