import React, { useState } from 'react'
import { Card, Tabs } from 'antd'
import HeaderLogo from '../../components/Gateway/HeaderLogo'
import VerifyEmail from './VerifyEmail'
import LoginForm from './LoginForm'
import SignupForm from './SignupForm'
import styles from '../../components/Gateway/Registration.module.css'
import ForgotPasswordWrapper from '../../components/Gateway/ForgotPassword/'
import { useQuery } from '../../services/urlQuery'

const { TabPane } = Tabs

const Registration = () => {
  const query = useQuery()

  let activeTab
  let confirmationToken
  let showRegistration = false

  switch (query.get('action')) {
    case 'login':
      activeTab = '2'
      break
    case 'invite':
      confirmationToken = query.get('confirmation')
      showRegistration = true
      activeTab = '1'
      break
    default:
      activeTab = '2'
  }
  const [activeKey, setActiveKey] = useState(activeTab)

  const isVerified = query.get('verified') === '1'
  // 0 signup, 1 verify, 2 verified
  const [verifyEmail, setVerifyEmail] = useState(isVerified ? 2 : 0)

  const tabsData = []

  if (showRegistration) {
    tabsData.push({
      tabName: 'Sign up',
      key: 1,
      content: (
        <SignupForm
            confirmationToken={confirmationToken}
            setVerifyEmail={setVerifyEmail}
        />
      )
    })
  }
  tabsData.push({
    tabName: 'Log in',
    key: 2,
    content: <LoginForm setActiveKey={setActiveKey} setVerifyEmail={setVerifyEmail} />
  })

  return (
        <Card className={styles.container} bodyStyle={{ padding: 0 }}>
            <HeaderLogo />
            {!verifyEmail
              ? (
                <>
                    <Tabs
                        defaultActiveKey={activeKey}
                        activeKey={activeKey}
                        onTabClick={(key) => setActiveKey(key)}
                    >
                        {tabsData.map((tab) => (
                            <TabPane tab={tab.tabName} key={tab.key}>
                                {tab.content}
                            </TabPane>
                        ))}
                    </Tabs>
                    {!activeKey && (
                        <div className={styles.forgotPasswordWrapper}>
                            <ForgotPasswordWrapper />
                        </div>
                    )}
                </>
                )
              : (
                <VerifyEmail verifyEmail={verifyEmail} />
                )}
        </Card>
  )
}
export default Registration
