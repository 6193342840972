import React from 'react'

const Briefcase = ({ fill = 'none', width = 20, height = 20 }) => {
  return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 20 20"
            fill={fill}
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M16.6665 5.83337H3.33317C2.4127 5.83337 1.6665 6.57957 1.6665 7.50004V15.8334C1.6665 16.7538 2.4127 17.5 3.33317 17.5H16.6665C17.587 17.5 18.3332 16.7538 18.3332 15.8334V7.50004C18.3332 6.57957 17.587 5.83337 16.6665 5.83337Z"
                stroke="#CACCCF"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M13.3332 17.5V4.16667C13.3332 3.72464 13.1576 3.30072 12.845 2.98816C12.5325 2.67559 12.1085 2.5 11.6665 2.5H8.33317C7.89114 2.5 7.46722 2.67559 7.15466 2.98816C6.8421 3.30072 6.6665 3.72464 6.6665 4.16667V17.5"
                stroke="#CACCCF"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
  )
}
export default Briefcase
