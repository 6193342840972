import * as actionTypes from './actionTypes'

export const avatarImage = (url) => {
  if (!url) {
    return {
      type: actionTypes.AVATAR_IMAGE_SET,
      value: ''
    }
  }
  return {
    type: actionTypes.AVATAR_IMAGE_SET,
    value: url.indexOf('http') === 0 ? url : `${window.REACT_APP_KALGERA_UMS_API_HOST}${url}`
  }
}
