import React, { useEffect, useState } from 'react'
import { Col, Radio } from 'antd'
import { CardContainer } from '@tutorbot/ui-components'
import ByEventList from './ByEventList/ByEventList'
import ByMonthList from './ByMonthList'
import styles from './Vulnerability.module.css'

const Vulnerability = ({ customerFiId }) => {
  const [internalView, setInternalView] = useState('ByMonth')
  const [internalComponent, setInternalComponent] = useState(null)
  const [selectedVulnerability, setSelectedVulnerability] = useState()

  useEffect(() => {
    switch (internalView) {
      case 'ByEvent':
      case 'ByMonthEvent':
        setInternalComponent(
                    <ByEventList
                        customerFiId={customerFiId}
                        selectedVulnerability={selectedVulnerability}
                        backPathClick={() => {
                          setInternalView('ByMonth')
                          setSelectedVulnerability({})
                        }}
                        showBreadcrumbs={internalView === 'ByMonthEvent'}
                    />
        )
        break
      case 'ByMonth':
      default:
        setInternalComponent(
                    <ByMonthList
                        customerFiId={customerFiId}
                        selectedVulnerability={selectedVulnerability}
                        selectVulnerability={(vb) => {
                          setSelectedVulnerability(vb)
                          setInternalView('ByMonthEvent')
                        }}
                    />
        )
    }
  }, [internalView, selectedVulnerability, customerFiId])

  return (
        <Col
            lg={{ span: 12 }}
            md={{ span: 24 }}
            sm={{ span: 24 }}
            xs={{ span: 24 }}
            className='gutter-row'
        >
            <div className={styles.vulnerability_container}>
                <CardContainer>
                    <Col className={styles.vulnerability_list}>
                        <h2 className={styles.label}>
                            Vulnerability
                        </h2>
                        <Col className={styles.btn_group}>
                            <Radio.Group defaultValue={1} buttonStyle={'solid'}>
                                <Radio.Button
                                    key={1}
                                    value={1}
                                    htmlType='button'
                                    className={styles.top_button}
                                    onClick={() => {
                                      setInternalView('ByMonth')
                                      setSelectedVulnerability({})
                                    }}
                                >
                                    By Month
                                </Radio.Button>
                                <Radio.Button
                                    key={2}
                                    value={2}
                                    htmlType='button'
                                    className={styles.top_button}
                                    onClick={() => {
                                      setInternalView('ByEvent')
                                      setSelectedVulnerability({})
                                    }}
                                >
                                    By Event
                                </Radio.Button>
                            </Radio.Group>
                        </Col>
                    </Col>

                    { internalComponent }

                </CardContainer>
            </div>

        </Col>
  )
}

export default Vulnerability
