import React from 'react'
import styles from './NoDataAvailable.module.css'
import { IconInBoxOutline } from '@tutorbot/ui-components'

const NoDataAvailable = () => {
  return (
        <div className={styles.empty_space}>
            <IconInBoxOutline size="lg" fill={'var(--black-20)'} />
            <span>No data available</span>
        </div>
  )
}

export default NoDataAvailable
