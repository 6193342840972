import React from 'react'
import Loader from 'react-loader-spinner'

const ContentLoader = ({ type, height, width, color, timeout = 0 }) => {
  return (
    <Loader
      type={type}
      color={color}
      height={height}
      width={width}
      timeout={timeout}
    />
  )
}

export default ContentLoader
