import React from 'react'
import Letter from '../../assets/icons/Letter'
import Lock from '../../assets/icons/Lock'
import Briefcase from '../../assets/icons/Briefcase'
import Person from '../../assets/icons/Person'

const emailAddress = {
  name: 'Email address',
  placeholder: 'ex: Johndoe@company.com',
  fieldName: 'email',
  icon: <Letter />,
  rules: [
    {
      required: true,
      type: 'email',
      message: ''
    },
    () => ({
      validator (_, value) {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        if (!value) {
          return Promise.reject(
            new Error('Please enter your email address')
          )
        }
        if (pattern.test(value)) {
          return Promise.resolve()
        }
        return Promise.reject(
          new Error('You have entered an invalid email address')
        )
      }
    })
  ]
}

const password = {
  name: 'Password',
  placeholder: '********',
  fieldName: 'password',
  type: 'password',
  icon: <Lock />,
  rules: [
    {
      required: true,
      message: ''
    },
    () => ({
      validator (_, value) {
        const pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*([0-9]|[!@#$%^&*]))(?=.*)(?=.{8,})/
        if (!value) {
          return Promise.reject(
            new Error('Please enter your password')
          )
        }
        if (pattern.test(value)) {
          return Promise.resolve()
        }
        return Promise.reject(
          new Error(
            'Your password must be longer than 8 characters, including a capital letter and a number or special character'
          )
        )
      }
    })
  ]
}

const newPassword = {
  name: 'New password',
  placeholder: '********',
  fieldName: 'password',
  type: 'password',
  icon: <Lock />,
  rules: [
    {
      required: true,
      message: ''
    },
    () => ({
      validator (_, value) {
        const pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*([0-9]|[!@#$%^&*]))(?=.*)(?=.{8,})/
        if (!value) {
          return Promise.reject(
            new Error('Please enter your password')
          )
        }
        if (pattern.test(value)) {
          return Promise.resolve()
        }
        return Promise.reject(
          new Error(
            'Your password must be longer than 8 characters, including a capital letter and a number or special character'
          )
        )
      }
    })
  ]
}

const confirmPassword = {
  name: 'Confirm password',
  placeholder: '********',
  fieldName: 'confirm_password',
  type: 'password',
  icon: <Lock />,
  rules: [
    {
      required: true,
      message: ''
    },
    ({ getFieldValue }) => ({
      validator (_, value) {
        if (!value) {
          return Promise.reject(
            new Error('Please re-enter your password')
          )
        }
        if (getFieldValue('password') === value) {
          return Promise.resolve()
        }

        return Promise.reject(
          new Error(
            'The two passwords that you entered do not match!'
          )
        )
      }
    })
  ]
}

const confirmNewPassword = {
  name: 'Confirm new password',
  placeholder: '********',
  fieldName: 'confirm_password',
  type: 'password',
  icon: <Lock />,
  rules: [
    {
      required: true,
      message: ''
    },
    ({ getFieldValue }) => ({
      validator (_, value) {
        if (!value) {
          return Promise.reject(
            new Error('Please re-enter your password')
          )
        }
        if (getFieldValue('password') === value) {
          return Promise.resolve()
        }

        return Promise.reject(
          new Error(
            'The two passwords that you entered do not match!'
          )
        )
      }
    })
  ]
}

const currentPassword = (error, setError) => {
  return {
    name: 'Current password',
    fieldName: 'currentPassword',
    rules: [
      {
        required: true,
        message: ''
      },
      () => ({
        validator (_, value) {
          if (error.message) {
            Error(error.message)
            setError({})
            return
          }

          const pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*([0-9]|[!@#$%^&*]))(?=.*)(?=.{8,})/
          if (!value) {
            return Promise.reject(
              new Error('Please enter your password')
            )
          }
          if (pattern.test(value)) {
            return Promise.resolve()
          }
          return Promise.reject(
            new Error(
              'Your password must be longer than 8 characters, including a capital letter and a number or special character'
            )
          )
        }
      })
    ]
  }
}
const jobTitle = {
  name: 'Job title',
  placeholder: 'ex: Data Analyst',
  fieldName: 'job_title',
  icon: <Briefcase />,
  rules: [
    { required: true, message: '' },
    () => ({
      validator (_, value) {
        const pattern = /^[a-zA-Z_ ]+$/
        if (!value) {
          return Promise.reject(
            new Error('Please enter your job title')
          )
        }
        if (pattern.test(value)) {
          return Promise.resolve()
        }
        return Promise.reject(
          new Error('The name entered contins invalid characters')
        )
      }
    })
  ]
}

const company = {
  name: 'Company',
  fieldName: 'company',
  rules: [
    {
      required: true,
      message: 'Please enter your company name'
    }
  ]
}

const firstName = {
  name: 'First name',
  placeholder: 'ex: John',
  fieldName: 'first_name',
  icon: <Person />,
  rules: [
    { required: true, message: '' },
    () => ({
      validator (_, value) {
        const pattern = /^[a-zA-Z_ ]+$/
        if (!value) {
          return Promise.reject(
            new Error('Please enter your first name')
          )
        }
        if (pattern.test(value)) {
          return Promise.resolve()
        }
        return Promise.reject(
          new Error('The name entered contains invalid characters')
        )
      }
    })
  ]
}

const lastName = {
  name: 'Last name',
  placeholder: 'ex: Doe',
  fieldName: 'last_name',
  icon: <Person />,
  rules: [
    { required: true, message: '' },
    () => ({
      validator (_, value) {
        const pattern = /^[a-zA-Z_ ]+$/
        if (!value) {
          return Promise.reject(
            new Error('Please enter your last name')
          )
        }
        if (pattern.test(value)) {
          return Promise.resolve()
        }
        return Promise.reject(
          new Error('The name entered contains invalid characters')
        )
      }
    })
  ]
}

export {
  firstName,
  lastName,
  jobTitle,
  emailAddress,
  password,
  confirmPassword,
  company,
  confirmNewPassword,
  newPassword,
  currentPassword
}
